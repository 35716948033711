/* eslint-disable react/no-unescaped-entities */
import { Button } from '@material-tailwind/react';
import { CardCaptureStepEnum, SellerPage, SellerPageTitle } from '@types';
import { ReactComponent as LeftIcon } from '@assets/images/left_icon.svg';
import VinIcon from '@assets/icons/VinIcon';
import OdometerIcon from '@assets/icons/OdometerIcon';
import PlateIcon from '@assets/icons/PlateIcon';
import VehicleIcon from '@assets/icons/VehiclePhotoIcon';
import { isMobile, isTablet } from 'react-device-detect';
import { useEffect, useState } from 'react';
import { ReactComponent as SuccessIcon } from '@assets/icons/Success.svg';
import { SellerVerifyDesktopQR } from '@components/widgets/SellerVerifyDesktopQR';
import { useOrgStore, useSellerStore } from '@stores';
import VehicleComponent from './CaptureProcess/CardCapture';
import clsx from 'clsx';

export const Vehicle = ({
  setPage
}: {
  setPage: (page: SellerPage) => void;
}) => {
  const [step, setStep] = useState('init');
  const { vinObj, odometer, vehicle, numberPlate, setActivePageTitle } =
    useSellerStore();
  const [primaryColor, setPrimaryColor] = useState('#002861');
  const { organisation } = useOrgStore();
  useEffect(() => {
    if (organisation.primaryColour !== '') {
      setPrimaryColor('#' + organisation.primaryColour);
    }
    setActivePageTitle(SellerPageTitle.vehicle);
  }, []);
  switch (step) {
    case 'vin':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.vin}
          setPage={setPage}
          init={() => setStep('init')}
        />
      );
    case 'plate':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.plateNumber}
          setPage={setPage}
          init={() => setStep('init')}
        />
      );
    case 'odometer':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.odometer}
          setPage={setPage}
          init={() => setStep('init')}
        />
      );
    case 'vehicles-photos':
      return (
        <VehicleComponent
          type={CardCaptureStepEnum.vehiclePhoto}
          setPage={setPage}
          init={() => setStep('init')}
        />
      );
    default:
      break;
  }
  return (
    <>
      <div className="flex py-8">
        <div className="grow">
          {(isMobile || isTablet) && (
            <>
              <p className="text-gray-700">
                By submitting the information below, you help us in confirming
                that your vehicle meets our sale condition standards.
              </p>
              <p className="text-gray-700 mt-4">
                <span className="font-semibold">
                  Please ensure that all photos are real-time captures of the
                  actual vehicle.
                </span>
                <span>
                  {' '}
                  Images of printed photos or screenshots will be detected and
                  rejected.
                </span>
              </p>
            </>
          )}
        </div>
      </div>
      {isMobile || isTablet ? (
        <div className="py-4 mx-auto w-full overflow-hidden relative">
          <Button
            onClick={() => setStep('vin')}
            className={clsx(
              'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
              !vinObj.available && 'hidden'
            )}>
            <div className="flex gap-3">
              <VinIcon color={primaryColor} />
              <p className="mr-2 text-lg text-semibold">VIN </p>
              {vinObj.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
            </div>
            <LeftIcon />
          </Button>
          <Button
            onClick={() => setStep('plate')}
            className={clsx(
              'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
              !numberPlate.available && 'hidden'
            )}>
            <div className="flex gap-3">
              <PlateIcon color={primaryColor} />
              <p className="mr-2">Number plate </p>
              {numberPlate.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
            </div>
            <LeftIcon />
          </Button>
          <Button
            onClick={() => setStep('odometer')}
            className={clsx(
              'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
              !odometer.available && 'hidden'
            )}>
            <div className="flex gap-3">
              <OdometerIcon color={primaryColor} />
              <p className="mr-2">Odometer</p>
              {odometer.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
            </div>
            <LeftIcon />
          </Button>
          <Button
            onClick={() => setStep('vehicles-photos')}
            className={clsx(
              'bg-grey-50 normal-case !rounded-none border-b border-grey-200 w-full p-5 text-dark-primary flex justify-between font-semibold text-lg',
              !vehicle.available && 'hidden'
            )}>
            <div className="flex gap-3">
              <VehicleIcon color={primaryColor} />
              <p className="mr-2">Vehicles Photos</p>
              {vehicle.isDone && <SuccessIcon className="mt-1 w-5 h-5" />}
            </div>
            <LeftIcon />
          </Button>
        </div>
      ) : (
        <>
          <h2 className="text-[24px] lg:text-[30px] font-semibold text-grey-900 -mt-12">
            Vehicle
          </h2>
          <SellerVerifyDesktopQR />
        </>
      )}
    </>
  );
};
