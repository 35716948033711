import { useEffect, useState } from 'react';
import { Button } from '@material-tailwind/react';
import { useInspectionDetailStore } from '@stores';
import {
  DocumentFormEnum,
  DocumentFormType,
  InspectionSecurityStatusEnum,
  LenderDocumentTypeEnum
} from '@types';
import {
  INSPECTION_SECURITY_CHECK_LABEL_STATUS,
  INSPECTION_SECURITY_CHECK_VARIANT
} from '@constants';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { format } from 'date-fns';
import StatusChip from '../../../../components/widgets/StatusChip';
import clsx from 'clsx';
import {
  ADD_INSPECTION_COMMENT,
  GET_INSPECTION_COMMENTS,
  GET_INSPECTION_FILE,
  post
} from '@services';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/widgets/Loader';
interface IProps {
  type: DocumentFormType;
  status: InspectionSecurityStatusEnum;
}

const SecurityCheckCard = ({ type, status }: IProps) => {
  const { activePanel, inspectionDetailData, isCollapsed, setCollapse } =
    useInspectionDetailStore();
  const formattedDate = (date: Date) => format(date, 'dd/MM/yyyy h:mma');
  const [verifyStatus, setVerifyStatus] = useState<string>();
  const [comments, setComments] = useState<any>([]);
  const [subChecks, setSubChecks] = useState<any>([]);
  const [showSuccessMsg, setSuccessMsg] = useState(false);
  const [comment, setComment] = useState('');
  const [isLoading, setLoading] = useState(true);
  const [fileId, setFileId] = useState('');
  const [licenseImgsrc, setLicenseImgSrc] = useState<any>();
  const [faceImgsrc, setFaceImgSrc] = useState<any>();
  const isDemo = location.pathname.includes('/demo/inspection');
  let fileTypes: {
    documentType: string;
    fileId: string;
  }[] = [];
  const param = useParams();
  const handleClose = () => {
    setCollapse(true);
  };
  const saveComment = () => {
    post(
      ADD_INSPECTION_COMMENT,
      {
        checkType: type,
        documentType: LenderDocumentTypeEnum.SELLER_ID,
        fileId: fileId,
        inspectionId: param.id,
        text: comment
      },
      false
    )
      .then((data) => {
        if (data.commentId) {
          setSuccessMsg(true);
        }
      })
      .catch((e: any) => {
        console.log(e);
      });
  };
  const toPercentage = (decimal: number): string => {
    return `${Math.round(decimal * 100)}%`;
  };
  useEffect(() => {
    if (
      type === DocumentFormEnum.DETAILS_DRIVER_LICENSE_AUTHENTICITY &&
      inspectionDetailData.inspectionChecks &&
      inspectionDetailData.inspectionChecks.licenseAuthenticity.subChecks
    ) {
      setSubChecks(
        inspectionDetailData.inspectionChecks.licenseAuthenticity.subChecks
      );
    }
    if (
      status &&
      (status === InspectionSecurityStatusEnum.warning ||
        status === InspectionSecurityStatusEnum.failed)
    ) {
      if (comment === '') return;
      const fileIds = inspectionDetailData.files.filter((file: any) =>
        [
          LenderDocumentTypeEnum.SELLER_ID,
          LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
          LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
          LenderDocumentTypeEnum.FACE
        ].includes(file.documentType)
      );
      const fileId = fileIds.length > 0 && fileIds[0].id;
      if (fileId) {
        setFileId(fileId);
      }
      post(GET_INSPECTION_COMMENTS, { inspectionId: param.id }, false)
        .then((data) => {
          if (data.inspectionComments) {
            setComments(data.inspectionComments);
          }
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
    if (showSuccessMsg) {
      const timer = setTimeout(() => {
        setSuccessMsg(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessMsg, type, isLoading]);
  switch (type) {
    case DocumentFormEnum.DETAILS_DRIVER_LICENSE_AUTHENTICITY:
      return (
        <>
          <div className="flex flex-col p-6 gap-6 border-t items-start h-full">
            {status && status === InspectionSecurityStatusEnum.warning && (
              <p className="font-medium text-grey-700 text-base bg-warning-50 p-4 rounded-md">
                Unable to verify with high confidence that the driver’s licence
                provided is authentic.{' '}
              </p>
            )}
            {status && status === InspectionSecurityStatusEnum.failed && (
              <p className="font-medium text-grey-700 text-base bg-error-50 p-4 rounded-md">
                Low confidence in the authenticity of the provided driver&apos;s
                license.
              </p>
            )}
            <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
              {_map(subChecks, (row: any, index: number) => (
                <div key={index} className="flex justify-start gap-x-2">
                  <div key={index}>
                    <p className="text-base text-grey-700">
                      {toPercentage(row.score)} confidence
                    </p>
                    <p className="text-grey-500 text-xs leading-5">
                      {row.name}
                    </p>
                  </div>
                  <div className="justify-self-end">
                    <StatusChip
                      variant={
                        INSPECTION_SECURITY_CHECK_VARIANT[
                          row.status as InspectionSecurityStatusEnum
                        ]
                      }
                      clsName={clsx('border text-xs font-normal leading-5')}
                      label={
                        row.type === 'LICENSE_EXPIRY' &&
                        status === InspectionSecurityStatusEnum.pass
                          ? 'Valid'
                          : row.type === 'LICENSE_EXPIRY' &&
                            status === InspectionSecurityStatusEnum.failed
                          ? 'Expired'
                          : INSPECTION_SECURITY_CHECK_LABEL_STATUS[
                              row.status as keyof typeof INSPECTION_SECURITY_CHECK_LABEL_STATUS
                            ]
                      }
                    />
                  </div>
                </div>
              ))}
            </div>
            {!isCollapsed && (
              <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                <Button
                  className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </>
      );
    case DocumentFormEnum.DETAILS_DRIVER_LICENSE_EXPIRY:
      fileTypes = inspectionDetailData.files.filter((e: any) =>
        ['DRIVER_LICENCE_FRONT'].includes(e.documentType)
      );
      if (!isDemo && fileTypes.length > 0) {
        post(
          GET_INSPECTION_FILE,
          { inspectionId: param.id, fileId: fileTypes[0].fileId },
          false
        )
          .then((data) => {
            setLoading(false);
            if (data && data.dataBase64) {
              setLicenseImgSrc(data.dataBase64);
            }
          })
          .catch((e) => {
            setLoading(false);
            console.error(e);
          });
        if (isLoading) return <Loader />;
      }
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <p className="font-medium text-grey-700 text-base bg-error-50 p-4 rounded-md">
                The Seller&apos;s driver&apos;s licence had expired at the time
                of submission.
              </p>
              <div className="flex flex-col gap-8">
                {licenseImgsrc && !isDemo && (
                  <img src={`data:image/jpeg;base64,${licenseImgsrc}`} />
                )}
                {isDemo && (
                  <img className="" src="/images/card_front.png" alt="card" />
                )}
              </div>
            </div>
            {!isCollapsed && (
              <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                <Button
                  className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    case DocumentFormEnum.DETAILS_DRIVER_LICENSE_DATA_EDIT:
      fileTypes = inspectionDetailData.files.filter(
        (e: any) => e.documentType === 'DRIVER_LICENCE_FRONT'
      );
      if (!isDemo && fileTypes.length > 0) {
        post(
          GET_INSPECTION_FILE,
          { inspectionId: param.id, fileId: fileTypes[0].fileId },
          false
        )
          .then((data) => {
            setLoading(false);
            //TODO: For MVP hide the add comments function AUT-218
            // setFileId(fileTypes[0].fileId);
            if (data && data.dataBase64) {
              setLicenseImgSrc(data.dataBase64);
            }
          })
          .catch((e) => {
            setLoading(false);
            console.error(e);
          });
        if (isLoading) return <Loader />;
      }
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <p className="font-medium text-grey-700 text-base bg-warning-50 p-4 rounded-md">
                The driver’s licence data recorded might be different compared
                to the information on the photo provided.
              </p>
              <div className="flex flex-col gap-8">
                {licenseImgsrc && !isDemo && (
                  <img src={`data:image/jpeg;base64,${licenseImgsrc}`} />
                )}
                {isDemo && (
                  <img className="" src="/images/card_front.png" alt="card" />
                )}
              </div>
              {fileId && fileId !== '' && (
                <div className="flex flex-col bg-grey-50 p-4 gap-6 border border-grey-200 rounded-lg">
                  <p className="font-medium text-base text-grey-700">
                    Add a comment to the verification report that provides
                    context to potential differences.
                  </p>
                  <ul>
                    {_map(
                      comments,
                      (comment: any, index: number) =>
                        _get(comment, 'text') && (
                          <li key={index} className="text-sm text-grey-700">
                            <span className="font-semibold">
                              {' '}
                              {comment?.user.firstName +
                                ' ' +
                                comment?.user.lastName}{' '}
                            </span>
                            <span>
                              {' '}
                              {formattedDate(new Date(comment.createTime))}{' '}
                            </span>
                            <p> {comment.text} </p>
                          </li>
                        )
                    )}
                  </ul>
                  <div className="flex flex-col gap-y-1.5">
                    <span className="font-medium text-sm text-grey-700">
                      Add comment to verification report (optional)
                    </span>
                    <textarea
                      className="w-full h-32 border rounded-lg py-3 px-3.5 gap-2 border-grey-300"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                      placeholder="Add a comment"
                    />
                    {showSuccessMsg && (
                      <span className="self-end text-sm text-grey-600">
                        Saved!
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            {!isCollapsed && (
              <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                <Button
                  className="font-semibold bg-white border-grey-300 text-grey-700 w-full text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  className={clsx(
                    'font-semibold bg-primary capitalize w-full md:w-1/2 text-base whitespace-nowrap',
                    (comment === '' || fileId === '') && 'hidden'
                  )}
                  onClick={saveComment}>
                  Save comment
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    case DocumentFormEnum.DETAILS_DRIVER_LICENSE_FACE_MATCH:
      fileTypes = inspectionDetailData.files.filter((e: any) =>
        ['DRIVER_LICENCE_FRONT', 'FACE'].includes(e.documentType)
      );
      if (!isDemo && fileTypes.length > 0) {
        post(
          GET_INSPECTION_FILE,
          { inspectionId: param.id, fileId: fileTypes[0].fileId },
          false
        )
          .then((data) => {
            setLoading(false);
            if (data && data.dataBase64) {
              if (fileTypes[0].documentType === 'DRIVER_LICENCE_FRONT') {
                setLicenseImgSrc(data.dataBase64);
              } else {
                setFaceImgSrc(data.dataBase64);
              }
              post(
                GET_INSPECTION_FILE,
                { inspectionId: param.id, fileId: fileTypes[1].fileId },
                false
              )
                .then((data) => {
                  setLoading(false);
                  if (data && data.dataBase64) {
                    if (fileTypes[1]?.documentType === 'DRIVER_LICENCE_FRONT') {
                      setLicenseImgSrc(data.dataBase64);
                    } else {
                      setFaceImgSrc(data.dataBase64);
                    }
                  }
                })
                .catch((e) => {
                  setLoading(false);
                  console.error(e);
                });
            }
          })
          .catch((e) => {
            setLoading(false);
            console.error(e);
          });

        if (isLoading) return <Loader />;
      }
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              {status && status === InspectionSecurityStatusEnum.warning && (
                <p className="font-medium text-grey-700 text-base bg-warning-50 p-4 rounded-md">
                  Unable to verify with high confidence that the picture on the
                  driver’s licence matches the selfie provided.{' '}
                </p>
              )}
              {status && status === InspectionSecurityStatusEnum.failed && (
                <p className="font-medium text-grey-700 text-base bg-error-50 p-4 rounded-md">
                  Low confidence that the picture on the driver’s licence
                  matches the selfie provided.
                </p>
              )}
              <div className="flex flex-col gap-2">
                {licenseImgsrc && !isDemo && (
                  <img src={`data:image/jpeg;base64,${licenseImgsrc}`} />
                )}
                {isDemo && (
                  <img className="" src="/images/card_front.png" alt="card" />
                )}
                {faceImgsrc && !isDemo && (
                  <img src={`data:image/jpeg;base64,${faceImgsrc}`} />
                )}
                {isDemo && (
                  <img
                    className="w-full h-full object-cover max-w-max self-center"
                    src="/images/demo_face_match.png"
                    alt="selfie"
                  />
                )}
              </div>
            </div>
            {!isCollapsed && (
              <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                <Button
                  className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    case DocumentFormEnum.DETAILS_DRIVER_LICENSE_LIVENESS:
      fileTypes = inspectionDetailData.files.filter((e: any) =>
        ['FACE'].includes(e.documentType)
      );
      if (!isDemo && fileTypes.length > 0) {
        post(
          GET_INSPECTION_FILE,
          { inspectionId: param.id, fileId: fileTypes[0].fileId },
          false
        )
          .then((data) => {
            setLoading(false);
            if (data && data.dataBase64) {
              setFaceImgSrc(data.dataBase64);
            }
          })
          .catch((e) => {
            setLoading(false);
            console.error(e);
          });
        if (isLoading) return <Loader />;
      }
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              {status && status === InspectionSecurityStatusEnum.warning && (
                <p className="font-medium text-grey-700 text-base bg-warning-50 p-4 rounded-md">
                  Unable to verify with high confidence that selfie was
                  completed by an actual person.
                </p>
              )}
              {status && status === InspectionSecurityStatusEnum.failed && (
                <p className="font-medium text-grey-700 text-base bg-error-50 p-4 rounded-md">
                  Low confidence that selfie process was completed by an actual
                  person.
                </p>
              )}
              <div className="flex flex-col gap-8 justify-center">
                {faceImgsrc && !isDemo && (
                  <img src={`data:image/jpeg;base64,${faceImgsrc}`} />
                )}
                {isDemo && (
                  <img
                    className="w-full h-full object-cover max-w-max self-center"
                    src="/images/demo_face_match.png"
                    alt="selfie"
                  />
                )}
              </div>
            </div>
          </div>
          {!isCollapsed && (
            <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
              <Button
                className="font-semibold bg-white border-grey-300 text-grey-700 text-base w-full capitalize"
                variant="outlined"
                onClick={handleClose}>
                Cancel
              </Button>
            </div>
          )}
        </div>
      );
    case DocumentFormEnum.DETAILS_DRIVER_LICENSE_OFFICIAL_SOURCE:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <p className="font-medium text-grey-700 text-base bg-error-50 p-4 rounded-md">
                Checking against government databases, the identification has
                failed the official source check.
              </p>
              {!isCollapsed && (
                <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                  <Button
                    className="font-semibold bg-white border-grey-300 text-grey-700 text-base w-auto capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    default:
      return <> </>;
  }
};
export default SecurityCheckCard;
