/* eslint-disable react/no-unescaped-entities */
import { DocumentCaptureStepEnum, SellerPage } from '@types';
import { useState } from 'react';
import DocumentComponent from './CaptureProcess/DocumentCapture';
import { useSellerStore } from '@stores';
export const Cert = ({ setPage }: { setPage: (page: SellerPage) => void }) => {
  const [step, setStep] = useState('init');
  const { state } = useSellerStore();
  return (
    <>
      <DocumentComponent
        type={
          state === 'QLD'
            ? DocumentCaptureStepEnum.safetyCert
            : DocumentCaptureStepEnum.cert
        }
        setPage={setPage}
        init={() => setStep('init')}
      />
    </>
  );
};
