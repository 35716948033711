import React, { useEffect, useState } from 'react';

import Input from '@components/forms/Input';
import Button from '@components/forms/Button';
import Select from '@components/forms/Select';
import { Option } from '@material-tailwind/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useInspectionDetailStore } from '@stores';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import Loader from '@components/widgets/Loader';
import { LIST_INSPECTION_TEMPLATES, GET_INSPECTION_TEMPLATE, post } from '@services';
import { useNavigate } from 'react-router-dom';
import _map from 'lodash/map';
import { Tooltip } from 'react-tooltip';
import { ReactComponent as Info } from '@assets/icons/info-circle.svg';

import { template } from 'lodash';
type Props = {
  onClose: () => void;
  onNext: (lender: any) => void;
};

const LenderStep: React.FC<Props> = ({ onClose, onNext }) => {
  const states = [
    { value: 'ACT', label: 'Australian Capital Territory' },
    { value: 'NSW', label: 'New South Wales' },
    { value: 'NT', label: 'Northern Territory' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'SA', label: 'South Australia' },
    { value: 'TAS', label: 'Tasmania' },
    { value: 'VIC', label: 'Victoria' },
    { value: 'WA', label: 'Western Australia' }
  ];
  const lenderFormSchema = yup.object({
    contractId: yup
      .string()
      .required('Please enter a reference number.')
      .max(100, 'Reference number cannot be longer than 100 characters.'),
    templateId: yup.string().required('Inspection template is required'),
    saleState: yup.string().required('Please select a state.')
  });
  const {
    inspectionDetailData,
    setLender,
    setSaleState,
    setLenderReference,
    setLenderUserId
  } = useInspectionDetailStore();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(lenderFormSchema),
    defaultValues: {
      contractId: inspectionDetailData.lenderReference,
      templateId: inspectionDetailData.lender.template?.templateId || '',
      saleState: inspectionDetailData.saleState || ''
    }
  });
  const { contractId, templateId, saleState } = watch();
  const handleTemplateChange = (value: any) => {
    setValue('templateId', value);
    const auth = localStorage.getItem('auth');
    const obj = JSON.parse(auth as any);
    const userId = localStorage.getItem('userId');
    const lender = localStorage.getItem(`lenders_${userId}` as any);
    const lenderBrokers = localStorage.getItem(
      `lenderBrokers_${userId}` as any
    );

    post(GET_INSPECTION_TEMPLATE, { templateId: value }).then((res) => {
      setLender({
        ...obj?.state?.user,
        lenderName: lender
          ? JSON.parse(lender)[0]?.organisationName
          : lenderBrokers
          ? JSON.parse(lenderBrokers)[0]?.lenderOrganisationName
          : '',
        template: res.template
      });
    });
  };
  const onSubmit = (data: any) => {
    const lender = { ...inspectionDetailData.lender, isDone: true };

    setLenderUserId(localStorage.getItem('userId'));
    setLenderReference(data.contractId);
    setLender(lender);
    setSaleState(data.saleState);
    onNext(selectedLender);
  };
  const navigate = useNavigate();
  const [templates, setTemplates] = useState([] as any);
  const [isLoading, setLoading] = useState(false);
  const [selectedLender, setSelectedLender] = useState<any>(null);
  const [lenderList, setLenderList] = useState([] as any);
  const [isLender, setIsLender] = useState(true);
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      const lender = localStorage.getItem(`lenders_${userId}` as any);
      const lenderBrokers = localStorage.getItem(
        `lenderBrokers_${userId}` as any
      );
      if (lender) {
        setIsLender(true);
        const lenderOrganisationId = JSON.parse(lender)[0].organisationId;
        setLoading(true);
        post(LIST_INSPECTION_TEMPLATES, { lenderOrganisationId }).then(
          (res) => {
            setLoading(false);
            if (res.templates) {
              setTemplates([
                { templateId: '', name: 'Select an inspection type' },
                ...res.templates
              ]);
            }
          }
        );
      } else if (lenderBrokers) {
        setIsLender(false);
        setLenderList(JSON.parse(lenderBrokers));
      } else {
        navigate('/');
      }
    }
  }, []);

  function getTemplateList(lenderOrganisationId: string) {
    setLoading(true);
    post(LIST_INSPECTION_TEMPLATES, { lenderOrganisationId }).then((res) => {
      setLoading(false);
      if (res.templates) {
        setTemplates([
          { templateId: '', name: 'Select an inspection type' },
          ...res.templates
        ]);
      }
    });
  }
  if (isLoading) return <Loader />;
  const handleLenderChange = (value: any) => {
    setSelectedLender(value);
    getTemplateList(value);
  };
  const handleStateChange = (value: any) => {
    setSaleState(value);
    setValue('saleState', value);
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="text-xl font-semibold text-grey-900 mb-6">Settings</h3>
      <Tooltip
        id="badge-tooltip"
        place="bottom"
        className="rounded-lg z-10 w-[320px] text-sm !text-grey-700 bg-[#F2F4F7] bg-opacity-100 opacity-100 !border-1 border-grey-200 shadow-xl p-4"
        offset={2}></Tooltip>

      {!isLender && (
        <div className="mt-5">
          <span className="text-[14px] text-grey-700 font-medium mt-4">
            Lender
          </span>
          <Select
            placeholder="Select an inspection type"
            value={selectedLender} // Convert the value to a string
            onChange={handleLenderChange}>
            {lenderList &&
              lenderList.map((item: any, index: number) => (
                <Option key={index} value={item.lenderOrganisationId}>
                  {item.lenderOrganisationName}
                </Option>
              ))}
          </Select>
        </div>
      )}
      <div className="mt-5">
        <span className="flex flex-row gap-1 text-[14px] text-grey-700 font-medium mt-4">
          Inspection Template
          <div
            data-tooltip-id="badge-tooltip"
            data-tooltip-content="The Inspection Template presets the required documents and steps for the inspection. Confirm with the lender if unsure.">
            <Info />
          </div>
        </span>
        <Select
          placeholder="Select an inspection type"
          value={templateId} // Convert the value to a string
          disabled={!isLender && !selectedLender}
          onChange={handleTemplateChange}>
          {templates &&
            templates.map((item: any, index: number) => (
              <Option
                key={index}
                value={item.templateId}
                disabled={item.templateId === ''}>
                {item.name}
              </Option>
            ))}
        </Select>
        {(() => {
          const selectedTemplate = templates.find(
            (item: any) => item.templateId === templateId
          );
          return selectedTemplate?.description ? (
            <span className="text-grey-600 text-2sm mt-[6px]">
              {selectedTemplate.description}
            </span>
          ) : null;
        })()}
        {errors.templateId && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.templateId.message}`}
          </span>
        )}
      </div>
      <div className="mt-5">
        <span className="flex flex-row gap-1 text-[14px] text-grey-700 font-medium mb-1">
          Lender reference number
          <div
            data-tooltip-id="badge-tooltip"
            data-tooltip-content="Usually the application or contract number from the lender, used by the lender to link the inspection to the finance agreement.">
            <Info />
          </div>
        </span>
        <Input
          placeholder="A-XXX XXX"
          {...register(`contractId`)}
          icon={errors.contractId && <ErrorIcon />}
          value={contractId}
        />
        {errors.contractId && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.contractId.message}`}
          </span>
        )}
      </div>
      <div className="mt-5">
        <span className="flex flex-row gap-1 text-[14px] text-grey-700 font-medium mb-1">
          Seller’s State / Territory
          <div
            data-tooltip-id="badge-tooltip"
            data-tooltip-content="The selected State or Territory will help determine whether a Roadworthy or Safety Certificate is required.">
            <Info />
          </div>
        </span>
        <Select
          placeholder="Select a State"
          {...register(`saleState`)}
          value={saleState}
          onChange={handleStateChange}>
          {states.map((state, index) => (
            <Option
              key={index}
              value={state.value}
              disabled={state.value === ''}>
              {state.label}
            </Option>
          ))}
        </Select>
        {errors.saleState && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.saleState.message}`}
          </span>
        )}
      </div>

      <div className="flex justify-end border-t border-t-grey-200 mt-4 pt-5">
        <Button
          type="submit"
          onSubmit={handleSubmit(onSubmit)}
          className="text-white bg-primary !text-sm">
          Next
        </Button>
      </div>
    </form>
  );
};

export default LenderStep;
