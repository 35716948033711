import { create } from 'zustand';
import { devtools, persist, createJSONStorage } from 'zustand/middleware';

export interface InvoiceDetailStoreState {
  invoiceDetailData: {
    seller: {
      applicationType: string;
      address: string;
      suburb: string;
      postcode: string;
      email: string;
      firstName: string;
      lastName: string;
      mobile: string;
      userId?: string;
      state?: string;
      bsb?: string;
      accountNumber?: string;
      isDone?: boolean;
      businessName?: string;
      businessNumber?: string;
      fullName?: string;
      phone?: string;
    };
    buyer: {
      email?: string;
      firstName?: string;
      lastName?: string;
      mobile?: string;
      address?: string;
      userId?: string;
      isDone?: boolean;
      state?: string;
      suburb?: string;
      postcode?: string;
    };
    vehicle: {
      make: string;
      model: string;
      year: string;
      vin: string;
      regoPlate: string;
      regoState: string;
      engineNumber: string;
      purchasePrice: number;
      deposit: number;
      balance: number;
      isDone: boolean;
    };
  };
  setInvoiceDetailData: (data: any) => void;
}

export const useInvoiceDetailStore = create<InvoiceDetailStoreState>()(
  devtools(
    persist(
      (set) => ({
        invoiceDetailData: {
          seller: {
            applicationType: '',
            address: '',
            suburb: '',
            postcode: '',
            email: '',
            firstName: '',
            lastName: '',
            mobile: '',
            bsb: '',
            accountNumber: '',
            isDone: false
          },
          buyer: {
            email: '',
            firstName: '',
            lastName: '',
            mobile: '',
            state: '',
            suburb: '',
            postcode: '',
            isDone: false
          },
          vehicle: {
            make: '',
            model: '',
            year: '',
            vin: '',
            regoPlate: '',
            regoState: '',
            engineNumber: '',
            purchasePrice: 0,
            deposit: 0,
            balance: 0,
            isDone: false
          }
        },
        setInvoiceDetailData: (data: any) =>
          set((state) => ({
            ...state,
            invoiceDetailData: data
          }))
      }),
      {
        name: 'invoiceDetails',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
);
