import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Button from '@components/forms/Button';
import StatusChip from '@components/widgets/StatusChip';
import Input from '@components/forms/Input';
import ActionMenu from '@components/widgets/ActionMenu';
import { ReactComponent as PencilIcon } from '@assets/icons/pencil_icon.svg';
import InspectionMainInfo from '@pages/Inspections/components/InspectionMainInfo';
import DetailedInfo from '@pages/Inspections/components/DetailedInfo';
import { ReactComponent as UserIcon } from '@assets/icons/light-user-icon.svg';
import { ReactComponent as LenderIcon } from '@assets/icons/lender_icon.svg';
import { ReactComponent as MailIcon } from '@assets/icons/mail_icon.svg';
import { ReactComponent as DownloadIcon } from '@assets/icons/download-icon.svg';
import { ReactComponent as PhoneIcon } from '@assets/icons/phone_icon.svg';
import { ReactComponent as AddressIcon } from '@assets/icons/address_icon.svg';
import { ReactComponent as CheckSuccessIcon } from '@assets/icons/check_activity_success_icon.svg';
import { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
  INSPECTION_CARD_TYPE,
  INSPECTION_CARD_TYPES,
  INSPECTION_STATUS_TEXT,
  LOGOS_LIST
} from '@constants';
import { INSPECTION_TABLE_ROWS } from '@constants';
import { useInspectionDetailStore, useOrgStore } from '@stores';
import _filter from 'lodash/filter';
import { Tooltip } from 'react-tooltip';
import ReactDOMServer from 'react-dom/server';
import _intersection from 'lodash/intersection';
import {
  COMPLETE_INSPECTION,
  GET_INSPECTION,
  GET_INSPECTION_CHECK,
  GET_INSPECTION_FILES,
  GET_INSPECTION_FILE,
  GET_INSPECTION_REPORT,
  GET_LENDER_ORGANISATION,
  SEND_INSPECTION_INVITE,
  UPDATE_INSPECTION,
  post
} from '@services';
import { BiLoaderAlt } from 'react-icons/bi';
import clsx from 'clsx';
import Modal from '@components/widgets/Modal';
import { InspectionSecurityStatusEnum, InspectionStatusEnum } from '@types';
import { useNavigate } from 'react-router-dom';
const DetailView = () => {
  const params = useParams();
  const {
    activeStep,
    setCollapse,
    setActiveStep,
    setActivePanel,
    inspectionDetailData,
    setInspectionDetailData,
    setInspectionChecks,
    setBuyer,
    setSeller,
    setActivity,
    setInspectionFiles
  } = useInspectionDetailStore();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();
  const { setOrg } = useOrgStore();
  const setStep = (step: string) => {
    setActiveStep(step);
    setActivePanel('DRIVER_LICENCE_FRONT');
  };
  const location = useLocation();
  const handleActivityHistoryButton = (status: boolean) => {
    setActivity(true);
    setCollapse(status);
  };

  const userId = localStorage.getItem('userId');
  const lenderUser = localStorage.getItem(`lenders_${userId}` as any);
  const [isLoading, setLoading] = useState(false);
  const [invoicePDF, setInvoicePDF] = useState('');
  const onDownload = () => {
    setLoading(true);
    post(GET_INSPECTION_REPORT, {
      inspectionId: params.id
    }).then((data) => {
      setLoading(false);
      const base64Pdf = data.base64Pdf;
      // const linkSource = `data:application/pdf;base64,${base64Pdf}`;
      const linkSource = data.downloadUrl;
      const fileName = 'inspection-report.pdf';
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.target = '_blank';
      downloadLink.click();
    });
  };

  const handleCompleteInspection = () => {
    post(COMPLETE_INSPECTION, { inspectionId: params.id }, false)
      .then(() => {
        setIsOpenModal(false);
        window.location.reload();
      })
      .catch((e) => {
        setIsOpenModal(false);
        console.error(e);
      });
  };

  useEffect(() => {
    setCollapse(true);
    setActivity(false);
    const currentData = _filter(INSPECTION_TABLE_ROWS, [
      'inspectionId',
      params.id
    ])[0];
    if (currentData) {
      setActivePanel('DRIVER_LICENCE_FRONT');
      setActiveStep('1');
      setInspectionDetailData(currentData);
    } else {
      const organisation =
        lenderUser && JSON.parse(lenderUser)[0].organisationId;
      post(GET_LENDER_ORGANISATION, { organisationId: organisation }, false)
        .then((data) => {
          if (data) {
            setOrg(data);
          }
        })
        .catch((e) => {
          console.error(e);
        });
      post(GET_INSPECTION, { inspectionId: params.id }, false)
        .then((data) => {
          if (data) {
            setInspectionDetailData(data.inspection);
            setBuyer(data.buyer);
            setSeller(data.seller);
            const docTypes = data.inspection.documentTypes;
            if (docTypes && docTypes.length > 0) {
              if (
                _intersection(docTypes, [
                  'SELLER_ID',
                  'DRIVER_LICENCE_FRONT',
                  'DRIVER_LICENCE_BACK',
                  'FACE'
                ]).length > 0
              ) {
                setActivePanel('DRIVER_LICENCE_FRONT');
                setActiveStep('1');
              } else if (
                _intersection(docTypes, [
                  'VIN',
                  'REGO_PLATE',
                  'ODOMETER',
                  'ASSET_FRONT',
                  'ASSET_BACK',
                  'ASSET_LEFT',
                  'ASSET_RIGHT'
                ]).length > 0
              ) {
                setActivePanel('VIN');
                setActiveStep('2');
              } else if (docTypes.includes('REGO_PAPERS')) {
                setActivePanel('regoPaper');
                setActiveStep('3');
              } else if (docTypes.includes('BANK_STATEMENTS')) {
                setActivePanel('bank');
                setActiveStep('4');
              } else if (
                docTypes.includes('ROADWORTHY_CERT') ||
                docTypes.includes('SAFETY_CERT')
              ) {
                setActivePanel('cert');
                setActiveStep('5');
              } else {
                setActiveStep('1');
                setActivePanel('DRIVER_LICENCE_FRONT');
              }
            } else {
              setActiveStep('1');
              setActivePanel('DRIVER_LICENCE_FRONT');
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
    if (!location.pathname.includes('/demo/inspection')) {
      post(GET_INSPECTION_CHECK, { inspectionId: params.id })
        .then((data) => {
          if (
            data &&
            data.inspectionChecks &&
            Object.keys(data.inspectionChecks).length !== 0
          ) {
            setInspectionChecks(data.inspectionChecks);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
    if (!location.pathname.includes('/demo/inspection')) {
      if (params.id && params.id !== '') {
        post(GET_INSPECTION_FILES, { inspectionId: params.id }, false)
          .then((data) => {
            if (data && data.files) {
              setInspectionFiles(data.files);
              const invoiceData = data.files.find(
                (e: any) => e.documentType === 'PRIVATE_SALE_INVOICE'
              );
              if (invoiceData) {
                post(
                  GET_INSPECTION_FILE,
                  { inspectionId: params.id, fileId: invoiceData.fileId },
                  false
                ).then((data) => {
                  setInvoicePDF(data.dataBase64);
                });
              }
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    }
  }, [params]);
  const [showSuccessMessage, setShowSuccessMessage] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState('');
  const [isOpenResendSMSModal, setIsOpenResendSMSModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleResendSMS = (mobile: string) => {
    post(
      UPDATE_INSPECTION,
      {
        inspectionId: params.id,
        seller: {
          mobile
        }
      },
      false
    )
      .then(() => {
        setInspectionDetailData({
          ...inspectionDetailData,
          seller: {
            ...inspectionDetailData.seller,
            mobile
          }
        });
        post(SEND_INSPECTION_INVITE, { inspectionId: params.id }, false)
          .then(() => {
            setShowSuccessMessage('Inspection Invite Sent');
            setShowErrorMessage('');
            setTimeout(() => {
              setIsOpenResendSMSModal(false);
            }, 4000);
          })
          .catch((e) => {
            console.error(e);
            if (
              e.response.data.error.message.includes(
                'An invitation has already been sent'
              )
            ) {
              setShowErrorMessage(
                'An invitation has already been sent to this seller within the last 2 hours. Please wait before sending another invitation.'
              );
            } else {
              setShowErrorMessage(e.response.data.error.message);
            }
            setShowSuccessMessage('');
          });
      })
      .catch((e) => {
        console.error(e.response.data);
        setShowErrorMessage("You don't have permission to resend SMS");
      });
  };
  return (
    <div>
      <div className="">
        <div className="flex flex-col justify-start lg:justify-between gap-y-4 w-full md:mb-6">
          <div className="w-ful flex flex-col gap-6 pt-6 pb-0 md:pb-6 px-3 lg:p-0">
            <div className="flex gap-3 items-center">
              <span className="text-base font-semibold text-grey-900">
                {inspectionDetailData.lenderReference !== '' &&
                  inspectionDetailData.lenderReference}{' '}
              </span>
              <span className="font-medium text-sm text-grey-600">
                {inspectionDetailData.friendlyId}
              </span>
              <StatusChip
                variant={
                  inspectionDetailData.status === InspectionStatusEnum.Initiated
                    ? 'info2'
                    : inspectionDetailData.status ===
                      InspectionStatusEnum.Completed
                    ? 'success1'
                    : 'info2'
                }
                label={
                  INSPECTION_STATUS_TEXT[
                    inspectionDetailData.status as InspectionStatusEnum
                  ]
                }
                clsName="rounded border-0"
              />
            </div>
            <div className="flex flex-col  2xl:flex-row gap-6 2xl:gap-0">
              <div className="mt-1 text-grey-600 gap-6 xl:gap-12 2xl:inline-flex grid 2xl:grid-cols-4 grid-cols-2 w-full xl:w-4/5">
                {inspectionDetailData.nevdisVehicle &&
                  inspectionDetailData.nevdisVehicle?.status ===
                    InspectionSecurityStatusEnum.reportFound && (
                    <IconCard
                      data={inspectionDetailData}
                      cardType="vehicleInfo"
                    />
                  )}
                {inspectionDetailData.buyer && (
                  <IconCard data={inspectionDetailData} cardType="buyerCard" />
                )}
                {inspectionDetailData.seller && (
                  <IconCard data={inspectionDetailData} cardType="sellerCard" />
                )}
                {inspectionDetailData.lender && (
                  <IconCard data={inspectionDetailData} cardType="lenderCard" />
                )}
                <Tooltip
                  id="badge-tooltip"
                  place="right"
                  className="rounded-lg z-10 bg-white bg-opacity-100 opacity-100 !border-1 border-grey-200 shadow-xl p-6"
                  offset={2}></Tooltip>
              </div>
              <div className="w-full 2xl:w-1/5 flex 2xl:justify-end 2xl:items-end sm:justify-start sm: items-start gap-2">
                <div>
                  <Button
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    className="w-[173px] bg-white border border-gray-300 hover:bg-gray-400 text-gray-800 text-sm font-[600] py-2 px-4 rounded inline-flex items-center"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    More Actions <KeyboardArrowDownIcon />
                  </Button>
                  <ActionMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button'
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        if (!invoicePDF) {
                          navigate(`/inspections/generateInvoice/${params.id}`);
                        } else {
                          const byteCharacters = atob(invoicePDF);
                          const byteNumbers = new Array(byteCharacters.length);
                          for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers[i] = byteCharacters.charCodeAt(i);
                          }
                          const byteArray = new Uint8Array(byteNumbers);
                          const blob = new Blob([byteArray], {
                            type: 'application/pdf'
                          });
                          const fileURL = URL.createObjectURL(blob);
                          window.open(fileURL, '_blank');
                        }
                      }}
                      disableRipple>
                      Generate Private Sales Invoice
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleClose();
                        setIsOpenResendSMSModal(true);
                        setShowSuccessMessage('');
                        setShowErrorMessage('');
                      }}
                      disableRipple>
                      Resend Inspection Invite SMS
                    </MenuItem>
                    <MenuItem
                      disabled={inspectionDetailData.files.length === 0}
                      onClick={() => {
                        handleClose();
                        onDownload();
                      }}
                      disableRipple>
                      {!isLoading ? (
                        <>
                          <DownloadIcon className="mr-2" /> Inspection Report
                        </>
                      ) : (
                        <>
                          <BiLoaderAlt className="animate-spin" />
                        </>
                      )}
                    </MenuItem>
                  </ActionMenu>
                </div>
                <Button
                  className={clsx(
                    'h-10 bg-primary !text-sm font-semibold capitalize  whitespace-nowrap px-4 py-2.5 flex flex-row gap-2 items-center justify-center min-w-[170px] min-h-[44px]',
                    inspectionDetailData.status ===
                      InspectionStatusEnum.Completed &&
                      'bg-secondary rounded-lg !opacity-100 !px-6 flex-shrink-0 w-[200px]'
                  )}
                  disabled={[InspectionStatusEnum.Completed].includes(
                    inspectionDetailData.status
                  )}
                  onClick={() => setIsOpenModal(true)}>
                  <span className="text-sm flex flex-row gap-2">
                    <span className="flex items-center">
                      {inspectionDetailData.status ===
                      InspectionStatusEnum.Completed ? (
                        <>
                          Inspection Closed
                          <CheckSuccessIcon className="w-4 h-4 ml-2" />
                        </>
                      ) : (
                        'Close Inspection'
                      )}
                    </span>
                  </span>
                </Button>
                <Button
                  className="h-10 bg-light border-grey-300 border shadow-none text-xs gap-2 text-grey-700 hover:bg-grey-100 focus:bg-grey-100 active:bg-grey-200 hidden  rounded-lg"
                  onClick={() => handleActivityHistoryButton(false)}>
                  <span className="text-sm">Activity history</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="gap-3 hidden">
            <Button className="h-10 text-primary bg-primary-light/10 shadow-none text-sm gap-2">
              <PencilIcon />
              <span className="hidden md:inline-block">Edit</span>
            </Button>
            <Button
              variant="outlined"
              className="h-10 border border-grey-300 text-grey-700 text-sm bg-white whitespace-nowrap">
              Cancel inspection
            </Button>
            <Button className="h-10 whitespace-nowrap">Send reminder</Button>
          </div>
        </div>
      </div>

      <div className="grid grid-flow-row xl:grid-cols-4 grid-cols-1 xl:flex xl:flex-row justify-between md:border-t md:border-t-grey-200 pt-0 gap-3 lg:gap-2 xl:gap-6 xl:border  pl-2 md:pl-0">
        <div className="flex gap-16 border-r border-grey-200 bg-none xl:w-64 md:py-6 md:px-5 max-w-[100vw] min-w-1/4 sm:min-w-[170px] no-scrollbar md:bg-grey-50">
          <div className="xl:flex-1 min-w-1/4 sm:min-w-[170px] no-scrollbar w-full">
            <DetailedInfo
              sendActiveStep={setStep}
              data={inspectionDetailData}
            />
          </div>
        </div>
        <div className="h-full md:col-span-3 xl:flex-1 xl:h-auto pr-2 pb-2 xl:pr-6 xl:pb-6 lg:pr-2 lg:pb-6">
          <InspectionMainInfo activeStep={activeStep} />
        </div>
      </div>
      <ConfirmInspectionModal
        open={isOpenModal}
        onSubmit={() => handleCompleteInspection()}
        onClose={() => setIsOpenModal(false)}
      />
      <ResendSMSModal
        open={isOpenResendSMSModal}
        showSuccessMessage={showSuccessMessage}
        showErrorMessage={showErrorMessage}
        mobile={inspectionDetailData?.seller?.mobile}
        onConfirm={handleResendSMS}
        onClose={() => setIsOpenResendSMSModal(false)}
      />
    </div>
  );
};
type ModalProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const ConfirmInspectionModal: React.FC<ModalProps> = ({
  open,
  onClose,
  onSubmit
}) => {
  return (
    <Modal size="md" title="Close Inspection" open={open} onClose={onClose}>
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-col py-2 gap-4">
          <div className="flex gap-2 flex-col">
            <h2 className="text-grey-500 font-bold text-sm flex gap-2 flex-row w-full">
              Before Closing Inspection
            </h2>

            <p className="text-grey-500 text-sm">
              Ensure you have downloaded the Inspection Report and saved it for
              your records.
            </p>
          </div>
          <div className="flex gap-2 flex-col">
            <h2 className="text-grey-500 font-bold text-sm flex gap-2 flex-row w-full">
              IMPORTANT
            </h2>
            <p className="text-grey-500 text-sm">
              Marking this inspection as &apos;Closed&apos; will finalise it,
              preventing any further edits. You can still access the details for
              7 days, after which it will be removed from Authentik8. This is
              done for data security purposes.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 w-full gap-4">
          <Button
            variant="outlined"
            data-dialog-close="true"
            onClick={onClose}
            className="border border-grey-300 bg-light text-grey-600 normal-case font-semibold rounded-lg text-base w-auto focus:ring focus:ring-white">
            Cancel
          </Button>
          <Button
            data-dialog-close="true"
            className="font-semibold bg-primary normal-case rounded-lg text-base text-white w-auto whitespace-nowrap"
            onClick={onSubmit}>
            Close Inspection
          </Button>
        </div>
      </div>
    </Modal>
  );
};

type Props = {
  data: any;
  cardType: INSPECTION_CARD_TYPES;
};
const IconCard: React.FC<Props> = ({ data, cardType }) => {
  const renderTooltip = (data: any, type: INSPECTION_CARD_TYPES) => {
    switch (type) {
      case INSPECTION_CARD_TYPE.buyerCard:
        return (
          <>
            <div className="flex flex-row gap-1 width-1/4 md:max-width-1/2">
              <div className="flex justify-center items-center w-12 h-12">
                <UserIcon />
              </div>
              <div className="flex flex-col w-full">
                <div className="text-grey-900 font-semibold">
                  {data.buyer.firstName + ' ' + data.buyer.lastName}
                </div>
                <div className="text-gray-light-grey font-medium text-sm">
                  Buyer
                </div>
              </div>
            </div>
            {data.buyer.email && (
              <div className="mt-2">
                <p className="text-gray-light-grey text-xs flex flex-row ">
                  <MailIcon />
                  <span className="ml-2">{data.buyer.email}</span>
                </p>
              </div>
            )}
            {data.buyer.mobile && (
              <div className="mt-2">
                <p className="text-gray-light-grey text-xs flex flex-row ">
                  <PhoneIcon />
                  <span className="ml-2"> {data.buyer.mobile}</span>
                </p>
              </div>
            )}
          </>
        );
      case INSPECTION_CARD_TYPE.sellerCard:
        return (
          <>
            <div className="flex flex-row gap-1 width-1/4 md:max-width-1/2 ">
              <div className="flex justify-center items-center w-12 h-12">
                <UserIcon />
              </div>
              <div className="flex flex-col w-full">
                <div className="text-grey-900 font-semibold">
                  {data.seller.firstName + ' ' + data.seller.lastName}
                </div>
                <div className="text-gray-light-grey font-medium text-sm">
                  Seller
                </div>
              </div>
            </div>
            {data.seller.email && (
              <div className="mt-2">
                <p className="text-gray-light-grey text-xs flex flex-row ">
                  <MailIcon />
                  <span className="ml-2">{data.seller.email}</span>
                </p>
              </div>
            )}
            {data.seller.mobile && (
              <div className="mt-2">
                <p className="text-gray-light-grey text-xs flex flex-row ">
                  <PhoneIcon />
                  <span className="ml-2"> {data.seller.mobile}</span>
                </p>
              </div>
            )}
            {data.seller.address2 && (
              <div className="mt-1">
                <p className="text-gray-light-grey text-xs flex flex-row items-center">
                  <AddressIcon />
                  <span className="ml-2 mt-1 self-center ">
                    {data.seller.address2
                      ? (data.seller.address2?.addressLine
                          ? data.seller.address2?.addressLine
                          : data.seller.address2?.streetNumber +
                            ' ' +
                            data.seller.address2?.streetName) +
                        ', ' +
                        data.seller.address2?.suburb +
                        ', ' +
                        data.seller.address2?.state +
                        ', ' +
                        data.seller.address2?.postCode
                      : ''}
                  </span>
                </p>
              </div>
            )}
          </>
        );
      case INSPECTION_CARD_TYPE.lenderCard:
        return (
          <>
            <div className="flex flex-row gap-1 width-1/4 md:max-width-1/2 ">
              <div className="flex justify-center items-center w-12 h-12">
                <LenderIcon />
              </div>
              <div className="flex flex-col w-full">
                <div className="text-grey-900 font-semibold">
                  {data.lender.firstName
                    ? data.lender.firstName + ' ' + data.lender.lastName
                    : data.lender.name}
                </div>
                <div className="text-gray-light-grey font-medium text-sm">
                  Lender
                </div>
              </div>
            </div>
            <div className="mt-2">
              <p className="text-gray-light-grey text-xs flex flex-row ">
                <MailIcon />
                <span className="ml-2">{data.lenderMail}</span>
              </p>
            </div>
            <div className="mt-2">
              <p className="text-gray-light-grey text-xs flex flex-row ">
                <PhoneIcon />
                <span className="ml-2"> {data.lenderPhone}</span>
              </p>
            </div>
          </>
        );
      default:
        return (
          <>
            <div className="flex flex-row gap-2 width-1/4 md:max-width-1/2 ">
              {data.vehicleMake &&
                LOGOS_LIST.includes(data.vehicleMake.toUpperCase()) && (
                  <div className="flex justify-center items-center w-24">
                    {/* <BrandIcon /> */}
                    <img
                      src={`/images/logo/${data.vehicleMake.toUpperCase()}.png`}
                      alt=""
                      className="w-[52px] h-[52px]"
                    />
                  </div>
                )}
              <div className="flex flex-col w-full">
                <div className="text-grey-900 font-semibold xl:truncate md:flex-wrap">
                  {data.vehicleModel}
                </div>
                <div className="text-gray-light-grey font-medium text-sm">
                  {data.vehicleYear}
                </div>
              </div>
            </div>
            <div className="flex flex-col w-full">
              <div className="text-grey-900 font-semibold xl:truncate md:flex-wrap">
                {data.nevdisVehicle.model}
              </div>
              <div className="text-gray-light-grey font-medium text-sm">
                {data.nevdisVehicle.buildYear}
              </div>
            </div>
          </>
        );
    }
  };

  const renderIconType = (data: any, type: INSPECTION_CARD_TYPES) => {
    const div = ReactDOMServer.renderToString(renderTooltip(data, type));
    switch (type) {
      case INSPECTION_CARD_TYPE.buyerCard:
        return (
          <div className="flex flex-row gap-1 width-1/4 md:max-width-1/2">
            <div
              className="flex justify-center items-center w-12 h-12"
              data-tooltip-id={
                data.buyer.mobile !== '' || data.buyer.email !== ''
                  ? 'badge-tooltip'
                  : ''
              }
              data-tooltip-html={div}>
              <UserIcon />
            </div>
            <div className="flex flex-col w-full">
              <div className="text-grey-900 font-semibold">
                {data.buyer.firstName + ' ' + data.buyer.lastName}
              </div>
              <div className="text-gray-light-grey font-medium text-sm">
                Buyer Name
              </div>
            </div>
          </div>
        );
      case INSPECTION_CARD_TYPE.sellerCard:
        return (
          <div className="flex flex-row gap-1 width-1/4 md:max-width-1/2 ">
            <div
              className="flex justify-center items-center w-12 h-12"
              data-tooltip-id="badge-tooltip"
              data-tooltip-html={div}>
              <UserIcon />
            </div>
            <div className="flex flex-col w-full">
              <div className="text-grey-900 font-semibold">
                {data.seller.firstName + ' ' + data.seller.lastName}
              </div>
              <div className="text-gray-light-grey font-medium text-sm">
                Seller Name
              </div>
            </div>
          </div>
        );
      case INSPECTION_CARD_TYPE.lenderCard:
        return (
          <div className="flex flex-row gap-1 width-1/4 md:max-width-1/2 ">
            <div className="flex justify-center items-center w-12 h-12">
              <LenderIcon />
            </div>
            <div className="flex flex-col w-full">
              <div className="text-grey-900 font-semibold">
                {data.lender.firstName
                  ? data.lender.firstName + ' ' + data.lender.lastName
                  : data.lender.name}
              </div>
              <div className="text-gray-light-grey font-medium text-sm">
                Lender Name
              </div>
            </div>
          </div>
        );
      default:
        return (
          <div className="flex flex-row gap-2 width-1/4 md:max-width-1/2 ">
            {data.vehicleMake &&
              data.vehicleMake !== '' &&
              LOGOS_LIST.includes(data.vehicleMake.toUpperCase()) && (
                <div className="flex justify-center items-center min-w-[52px] w-[52px] h-[52px]">
                  {/* <BrandIcon /> */}
                  <img
                    src={`/images/logo/${data.vehicleMake.toUpperCase()}.png`}
                    alt=""
                    className="w-[52px] h-[52px]"
                  />
                </div>
              )}
            <div className="flex flex-col w-full">
              <div className="text-grey-900 font-semibold xl:truncate md:flex-wrap">
                {data.vehicleModel}
              </div>
              <div className="text-gray-light-grey font-medium text-sm">
                {data.vehicleYear}
              </div>
            </div>
          </div>
        );
    }
  };

  return renderIconType(data, cardType);
};

const ResendSMSModal = ({
  open,
  mobile,
  onClose,
  showSuccessMessage,
  showErrorMessage,
  onConfirm
}: {
  open: boolean;
  showSuccessMessage: string;
  showErrorMessage: string;
  mobile: any;
  onClose: any;
  onConfirm: any;
}) => {
  const [mobileNumber, setMobileNumber] = useState(mobile);
  useEffect(() => {
    // Update mobileNumber when the mobile prop changes
    setMobileNumber(mobile);
  }, [mobile]);
  const handleMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMobileNumber(e.target.value);
  };
  return (
    <Modal
      title="Resend Inspection Invite SMS"
      size="md"
      open={open}
      onClose={onClose}>
      <div className="flex flex-col gap-[10px]">
        <div className="flex flex-col gap-2 text-[#475467]">
          <p>
            You can update the seller&apos;s mobile number below if needed. The
            seller’s inspection will now be accessible through this new invite,
            and their existing progress will be maintained
          </p>
        </div>
        <div className="mt-4">
          <p className="text-sm font-medium text-grey-700 mb-1.5">Mobile</p>
          <Input
            placeholder="Mobile"
            type="mobile"
            value={mobileNumber}
            onChange={handleMobileChange}
          />
        </div>

        <div className="mt-2">
          {showSuccessMessage && showSuccessMessage !== '' && (
            <div className="h-4 w-full text-right mb-4">
              <div className=" text-green-500">Inspection Invite Sent</div>
            </div>
          )}
          {showErrorMessage && showErrorMessage !== '' && (
            <div className="w-full text-center mb-4">
              <div className=" text-error-500">{showErrorMessage}</div>
            </div>
          )}
          <div className="flex mt-4 gap-4 justify-center lg:justify-end">
            <Button
              variant="outlined"
              data-dialog-close="true"
              onClick={onClose}
              className="border bg-white border-grey-300 text-grey-600 normal-case font-semibold rounded-lg text-base w-auto focus:ring focus:ring-white">
              Cancel
            </Button>
            <Button
              data-dialog-close="true"
              className="font-semibold bg-primary normal-case rounded-lg text-base text-white w-auto whitespace-nowrap"
              onClick={() => onConfirm(mobileNumber)}>
              Resend Invite
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default DetailView;
