import { Outlet } from 'react-router-dom';
import SellerFooter from './SellerFooter';
import { useMediaQuery } from 'usehooks-ts';
const SellerLayout = () => {
  const isMobile = useMediaQuery('(max-width: 720px)');
  return (
    <div className="w-full flex flex-col bg-grey-50 overflow-y-auto">
      <div className="flex-auto flex flex-col bg-grey-50 relative no-scrollbar overflow-y-hidden justify-center">
        <Outlet />
      </div>
      <div className="fixed bottom-0 left-0 w-full">
        <SellerFooter />
      </div>
    </div>
  );
};

export default SellerLayout;
