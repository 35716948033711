import React, { useEffect, useState, useRef } from 'react';
import Input from '@components/forms/Input';
import Button from '@components/forms/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Select from '@components/forms/Select';
import { Option } from '@material-tailwind/react';
import { ReactComponent as ErrorIcon } from '@assets/icons/info_error_light.svg';
import { ReactComponent as Info } from '@assets/icons/info-circle.svg';
import { useInspectionDetailStore } from '@stores';
import { SEARCH_ADDRESS, GET_ADDRESS, post } from '@services';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { Tooltip } from 'react-tooltip';
import BuyerDetailStep from './BuyerDetailStep';
type Props = {
  onClose: () => void;
  onPrevious: () => void;
  onNext: () => void;
};

const SellerDetailStep: React.FC<Props> = ({ onClose, onPrevious, onNext }) => {
  const phoneRegExp = /^(04\d{8}|\+614\d{8}|614\d{8})$/;
  const [items, setItems] = useState([
    {
      text: ''
    }
  ]);

  const states = [
    { value: '', label: 'Select a State' },
    { value: 'ACT', label: 'Australian Capital Territory' },
    { value: 'NSW', label: 'New South Wales' },
    { value: 'NT', label: 'Northern Territory' },
    { value: 'QLD', label: 'Queensland' },
    { value: 'SA', label: 'South Australia' },
    { value: 'TAS', label: 'Tasmania' },
    { value: 'VIC', label: 'Victoria' },
    { value: 'WA', label: 'Western Australia' }
  ];
  const sellerFormSchema = yup
    .object({
      email: yup
        .string()
        .required('Please enter your email.')
        .email('Please provide a valid email address'),
      firstName: yup
        .string()
        .required('Please enter the seller’s first name')
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field '),
      lastName: yup.string().required('Please enter the seller’s last name'),
      mobile: yup
        .string()
        .matches(
          phoneRegExp,
          'Invalid mobile number.  Please enter a valid mobile.'
        )
        .required('Please enter your mobile.'),
      // address: yup.string().required('Please provide the seller’s address'),
      saleState: yup.string().required('Please select a state.'),
      // postcode: yup.string().required('Please enter the postcode.'),
      // suburb: yup.string().required('Please enter the suburb.'),
      streetNumber: yup.string(),
      streetName: yup.string(),
      unitNumber: yup.string()
    })
    .required();
  const { inspectionDetailData, setSeller, setSaleState } =
    useInspectionDetailStore();
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(sellerFormSchema),
    defaultValues: {
      email: inspectionDetailData.seller.email,
      firstName: inspectionDetailData.seller.firstName,
      lastName: inspectionDetailData.seller.lastName,
      mobile: inspectionDetailData.seller.mobile,
      saleState: inspectionDetailData.saleState,
      streetNumber: '',
      streetName: '',
      unitNumber: ''
    }
  });
  const { firstName, lastName, email, mobile, saleState } = watch();
  const buyerFormRef = useRef<any>(null);

  const onSubmit = async (data: any) => {
    const seller = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: data.mobile,
      saleState: inspectionDetailData.saleState,
      postcode: data.postcode,
      isDone: true
    };
    setSeller(seller);
    if (inspectionDetailData?.lender?.template?.buyerRequired) {
      const validBuyer = await buyerFormRef.current?.submitForm();
      if (validBuyer) {
        onNext();
      }
    } else {
      onNext();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3 className="text-xl font-semibold text-grey-900 mb-6">
        Seller Details
      </h3>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">First name</p>
        <Input
          placeholder="First name"
          {...register(`firstName`)}
          onKeyDown={(event) => {
            if (!/[A-Za-z]+/i.test(event.key)) {
              event.preventDefault();
            }
          }}
          icon={errors.firstName && <ErrorIcon />}
          value={firstName}
        />
        {errors.firstName && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.firstName.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">Last name</p>
        <Input
          placeholder="Last name"
          {...register(`lastName`)}
          onKeyDown={(event) => {
            if (!/[A-Za-z]+/i.test(event.key)) {
              event.preventDefault();
            }
          }}
          icon={errors.lastName && <ErrorIcon />}
          value={lastName}
        />
        {errors.lastName && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.lastName.message}`}
          </span>
        )}
      </div>
      <div className="mb-5">
        <p className="text-sm text-grey-700 font-medium mb-1.5">Email</p>
        <Input
          placeholder="Email"
          {...register(`email`)}
          value={email}
          icon={errors.email && <ErrorIcon />}
        />
        {errors.email && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.email.message}`}
          </span>
        )}
      </div>
      <div className="mb-12">
        <p className="text-sm text-grey-700 font-medium mb-1.5">Mobile</p>
        <Input
          placeholder="Mobile number"
          {...register(`mobile`)}
          icon={errors.mobile && <ErrorIcon />}
          value={mobile}
        />
        {errors.mobile && (
          <span className="text-error-500 text-2sm mt-[6px]">
            {`${errors.mobile.message}`}
          </span>
        )}
      </div>
      <Tooltip
        id="badge-tooltip"
        place="bottom"
        className="rounded-lg z-10 w-[320px] text-sm !text-grey-700 bg-[#F2F4F7] bg-opacity-100 opacity-100 !border-1 border-grey-200 shadow-xl p-4"
        offset={2}></Tooltip>
      {inspectionDetailData?.lender?.template?.buyerRequired && (
        <BuyerDetailStep ref={buyerFormRef} />
      )}
      <div className="flex justify-between border-t border-t-grey-200 pt-5 mt-4  gap-3">
        <div className="flex flex-row gap-2">
          <Button
            className="bg-white text-grey-700 border-grey-300 !text-sm"
            variant="outlined"
            onClick={onPrevious}>
            Previous
          </Button>
        </div>
        <div className="flex flex-row gap-2">
          <Button type="submit" className="text-white bg-primary !text-sm">
            Next
          </Button>
        </div>
      </div>
    </form>
  );
};

export default SellerDetailStep;
