import { useEffect, useState } from 'react';
import { Radio, Button } from '@material-tailwind/react';
import { useInspectionDetailStore } from '@stores';
import {
  DETAILS_DRIVER_LICENSE_FRONT,
  DETAILS_DRIVER_LICENSE_BACK,
  DETAILS_DRIVER_LICENSE_SELFIE,
  DETAILS_BANK,
  DETAILS_CERTIFICATE,
  DETAILS_REGO_PHOTO_BACK,
  DETAILS_REGO_PHOTO_FRONT,
  DETAILS_VEHICLE_ASSET_BACK,
  DETAILS_VEHICLE_ASSET_FRONT,
  DETAILS_VEHICLE_DRIVER_SIDE,
  DETAILS_VEHICLE_ODOMETER,
  DETAILS_VEHICLE_PASSENGER_SIDE,
  DETAILS_VEHICLE_PLATE,
  DETAILS_VEHICLE_VIN,
  DETAILS_VEHICLE_VIN_LOCATION,
  DETAILS_VEHICLE_PLATE_LOCATION
} from '@constants';
import { DocumentFormEnum, DocumentFormType } from '@types';
import _get from 'lodash/get';
import _map from 'lodash/map';
import { format } from 'date-fns';
import DriverLicense from '/images/card_front.png';
import VehicleImg from '/images/vehicle.png';
import RegoImg from '/images/rego.png';
import BankImg from '/images/bank.png';
import CertImg from '/images/cert.png';
import { post, GET_INSPECTION_FILE } from '@services';
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/widgets/Loader';
import _groupBy from 'lodash/groupBy';
import _maxBy from 'lodash/maxBy';
import _flatMap from 'lodash/flatMap';
interface IProps {
  type: DocumentFormType;
}

const DocumentCard = ({ type }: IProps) => {
  const {
    activePanel,
    isCollapsed,
    setCollapse,
    inspectionDetailData,
    activeStep
  } = useInspectionDetailStore();
  const isDemo = location.pathname.includes('/demo/inspection');
  const formattedDate = (date: any) => format(date, 'dd/MM/yyyy h:mma');
  const [verifyStatus, setVerifyStatus] = useState<string>();
  const [formElements, setFormElements] = useState<any>([]);
  const [specialFormElements, setSpecialFormElements] = useState<any>([]);
  const [seller, setSeller] = useState<any>([]);
  const [isLoading, setLoading] = useState(true);
  const [imgsrc, setImgSrc] = useState<any>();
  const param = useParams();
  const [selectedFile, setSelectedFile] = useState<any>();
  const driverLicense = {
    location: '16 Kent St Quakers Hill NSW 2763',
    timestamp: '2024-05-30T22:48:44.887377Z'
  };
  const handleClose = () => {
    setCollapse(true);
  };
  const getLatestFiles = (files: any) => {
    const groupedFiles = _groupBy(files, 'documentType');

    const latestFiles = _flatMap(groupedFiles, (group) =>
      _maxBy(group, 'createdTime')
    );

    return latestFiles;
  };
  const files = getLatestFiles(inspectionDetailData.files);
  useEffect(() => {
    let fileTypes = [];
    switch (type) {
      case DocumentFormEnum.DETAILS_DRIVER_LICENSE_FRONT:
        fileTypes = files.filter(
          (e: any) => e.documentType === 'DRIVER_LICENCE_FRONT'
        );
        setFormElements(DETAILS_DRIVER_LICENSE_FRONT);
        break;
      case DocumentFormEnum.DETAILS_DRIVER_LICENSE_BACK:
        fileTypes = files.filter(
          (e: any) => e.documentType === 'DRIVER_LICENCE_BACK'
        );
        setFormElements(DETAILS_DRIVER_LICENSE_BACK);
        break;
      case DocumentFormEnum.DETAILS_DRIVER_LICENSE_SELFIE:
        fileTypes = files.filter((e: any) => e.documentType === 'FACE');
        setFormElements(DETAILS_DRIVER_LICENSE_SELFIE);
        break;
      case DocumentFormEnum.DETAILS_BANK:
        fileTypes = files.filter(
          (e: any) => e.documentType === 'BANK_STATEMENTS'
        );
        setFormElements(DETAILS_BANK);
        break;
      case DocumentFormEnum.DETAILS_CERTIFICATE:
        fileTypes = files.filter(
          (e: any) => e.documentType === 'ROADWORTHY_CERT'
        );
        setFormElements(DETAILS_CERTIFICATE);
        break;
      case DocumentFormEnum.DETAILS_REGO_PHOTO_BACK:
        fileTypes = files.filter((e: any) => e.documentType === 'REGO_PAPERS');
        setFormElements(DETAILS_REGO_PHOTO_BACK);
        break;
      case DocumentFormEnum.DETAILS_REGO_PHOTO_FRONT:
        fileTypes = files.filter((e: any) => e.documentType === 'REGO_PAPERS');
        setFormElements(DETAILS_REGO_PHOTO_FRONT);
        break;
      case DocumentFormEnum.DETAILS_VEHICLE_ASSET_BACK:
        fileTypes = files.filter((e: any) => e.documentType === 'ASSET_BACK');
        setFormElements(DETAILS_VEHICLE_ASSET_BACK);
        break;
      case DocumentFormEnum.DETAILS_VEHICLE_ASSET_FRONT:
        fileTypes = files.filter((e: any) => e.documentType === 'ASSET_FRONT');
        setFormElements(DETAILS_VEHICLE_ASSET_FRONT);
        break;
      case DocumentFormEnum.DETAILS_VEHICLE_DRIVER_SIDE:
        fileTypes = files.filter((e: any) => e.documentType === 'ASSET_RIGHT');
        setFormElements(DETAILS_VEHICLE_DRIVER_SIDE);
        break;
      case DocumentFormEnum.DETAILS_VEHICLE_ODOMETER:
        fileTypes = files.filter((e: any) => e.documentType === 'ODOMETER');
        setFormElements(DETAILS_VEHICLE_ODOMETER);
        break;
      case DocumentFormEnum.DETAILS_VEHICLE_PASSENGER_SIDE:
        fileTypes = files.filter((e: any) => e.documentType === 'ASSET_LEFT');
        setFormElements(DETAILS_VEHICLE_PASSENGER_SIDE);
        break;
      case DocumentFormEnum.DETAILS_VEHICLE_PLATE:
        fileTypes = files.filter((e: any) => e.documentType === 'REGO_PLATE');
        setFormElements(DETAILS_VEHICLE_PLATE_LOCATION);
        setSpecialFormElements(DETAILS_VEHICLE_PLATE);
        break;
      case DocumentFormEnum.DETAILS_VEHICLE_VIN:
        fileTypes = files.filter((e: any) => e.documentType === 'VIN');
        setFormElements(DETAILS_VEHICLE_VIN_LOCATION);
        setSpecialFormElements(DETAILS_VEHICLE_VIN);
        break;
    }
    if (!isDemo) {
      if (fileTypes.length > 0) {
        post(
          GET_INSPECTION_FILE,
          { inspectionId: param.id, fileId: fileTypes[0].fileId },
          false
        )
          .then((data) => {
            setLoading(false);
            if (data && data.file && data.file) {
              setSeller(data.file.user);
              setSelectedFile(data.file);
            }
            if (data && data.dataBase64) {
              setImgSrc(data.dataBase64);
            }
          })
          .catch((e) => {
            setLoading(false);
            console.error(e);
          });
      }
    } else {
      setLoading(false);
      setSelectedFile(driverLicense);
      setImgSrc(
        activeStep === '1'
          ? DriverLicense
          : activeStep === '2'
          ? VehicleImg
          : activeStep === '3'
          ? RegoImg
          : activeStep === '4'
          ? BankImg
          : CertImg
      );
    }
  }, [type]);
  if (isLoading) return <Loader />;
  return (
    <div className="flex flex-col p-6 gap-6 border-t h-full">
      <div className="flex gap-x-2 h-full flex-col justify-between">
        <div className="flex flex-col w-full gap-y-2">
          <h1 className="text-grey-800 font-semibold text-base">
            Provided by{' '}
            {seller
              ? seller.firstName + ' ' + seller.lastName
              : inspectionDetailData.seller.firstName +
                ' ' +
                inspectionDetailData.seller.lastName}
          </h1>

          <div className="flex flex-col gap-8">
            {imgsrc && !isDemo && (
              <img src={`data:image/jpeg;base64,${imgsrc}`} />
            )}
            {isDemo && <img className="" src={imgsrc} alt="card" />}
          </div>
          {specialFormElements.length > 0 &&
            _map(
              specialFormElements,
              (row: any, index: number) =>
                _get(selectedFile, row.keyPath) && (
                  <div
                    key={index}
                    className="flex flex-row justify-between border-b py-3.5 mt-[-8px]">
                    <p className="text-grey-500 text-sm leading-5">
                      {row.displayName}
                    </p>
                    <p className="text-base text-grey-700">
                      {_get(selectedFile, row.keyPath)}
                    </p>
                  </div>
                )
            )}
          <div className="flex flex-col justify-center items-center">
            {_map(
              formElements,
              (row: any, index: number) =>
                (_get(selectedFile, row.keyPath) ||
                  (row.key === 'location' &&
                    _get(selectedFile, 'clientAddress'))) && (
                  <div key={index} className="flex flex-row gap-x-1">
                    <p className="text-grey-500 text-xs leading-[18px]">
                      {row.displayName + ':'}
                    </p>
                    <p className="text-grey-500 text-xs leading-[18px]">
                      {row.type === 'Date'
                        ? formattedDate(_get(selectedFile, row.keyPath))
                        : row.keyPath === 'location'
                        ? _get(selectedFile, 'clientAddress.label')
                        : _get(selectedFile, row.keyPath)}
                    </p>
                  </div>
                )
            )}
          </div>
          {activePanel.status === 'Incomplete' && type === 'DETAILS_BANK' && (
            <div className="flex flex-col border-grey-200 border p-1 bg-grey-50 rounded-lg hidden">
              <Radio
                checked={verifyStatus === 'reviewed'}
                onChange={() => setVerifyStatus('reviewed')}
                color="green"
                name="type"
                label="Verified through manual review"
              />
              <Radio
                checked={verifyStatus === 'deny'}
                onChange={() => setVerifyStatus('deny')}
                color="red"
                name="type"
                label="Cannot verify"
              />
              <Radio
                checked={verifyStatus === 'resupply'}
                color="orange"
                onChange={() => setVerifyStatus('resupply')}
                name="type"
                label="Request seller to resupply"
              />
            </div>
          )}
        </div>
        {!isCollapsed &&
          (type !== 'DETAILS_BANK' ||
            (type === 'DETAILS_BANK' &&
              activePanel.status !== 'Incomplete')) && (
            <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
              <Button
                className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                variant="outlined"
                onClick={handleClose}>
                Cancel
              </Button>
            </div>
          )}
        {!isCollapsed &&
          activePanel.status === 'Incomplete' &&
          type === 'DETAILS_BANK' && (
            <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
              <Button
                className="font-semibold bg-white border-grey-300 text-grey-700 w-full text-base capitalize"
                variant="outlined"
                onClick={handleClose}>
                Cancel
              </Button>
              <Button className="font-semibold bg-primary w-1/2 text-base capitalize hidden">
                Submit review
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};
export default DocumentCard;
