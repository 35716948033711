import { useEffect, useRef, useState } from 'react';
import StatusListItem from '@components/widgets/StatusListItem';
import {
  DETAIL_VIEW_ITEMS,
  DOCUMENT_LIST,
  INSPECTION_SECURITY_CHECK_VARIANT,
  SECURITY_CHECK_LIST
} from '@constants';
import {
  BankSecurityCheckEnum,
  InspectionSecurityStatusEnum,
  InspectionStatusEnum,
  LenderDocumentTypeEnum,
  NevdisSecurityCheckEnum,
  RegoSecurityCheckEnum
} from '@types';
import _reduce from 'lodash/reduce';
import {
  CircularProgressbarWithChildren,
  buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useInspectionDetailStore } from '@stores';
import SemiCircleProgress from '@components/widgets/SemiCircleProgressBar';
import { useMediaQuery } from 'usehooks-ts';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import _intersection from 'lodash/intersection';
import _uniqBy from 'lodash/uniqBy';

type Props = {
  sendActiveStep: (step: string) => void;
  data: any;
};
const DetailedInfo = ({ sendActiveStep, data }: Props) => {
  const isMobile = useMediaQuery('(max-width: 1140px)');
  const [countOfAll, setCountOfAll] = useState({ done: 0, total: 10 });
  const {
    setActivity,
    setCollapse,
    inspectionDetailData,
    activeStep,
    setActiveStep
  } = useInspectionDetailStore();
  useEffect(() => {
    setActivity(false);
    sendActiveStep(activeStep);
    setCollapse(true);
    const totalObj = {
      done: _reduce(
        inspectionDetailData.documentTypes.map((item: any) => {
          // Get unique files by documentType
          const uniqueFiles = _uniqBy(
            inspectionDetailData.files,
            'documentType'
          );

          if (item === 'SELLER_ID') {
            return uniqueFiles.filter((file: any) =>
              [
                LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
                LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
                LenderDocumentTypeEnum.FACE
              ].includes(file.documentType)
            ).length;
          } else if (
            [
              LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
              LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
              LenderDocumentTypeEnum.FACE
            ].includes(item) &&
            inspectionDetailData.documentTypes.includes('SELLER_ID')
          ) {
            return 0;
          } else if (
            ['PPSR', 'NEVDIS', 'RAV', 'GLASSES', 'SAFETY_CERT'].includes(item)
          ) {
            return 0;
          } else {
            return uniqueFiles.some((file: any) => file.documentType === item)
              ? 1
              : 0;
          }
        }),
        (result, value) => result + value,
        0
      ),
      total: _reduce(
        inspectionDetailData.documentTypes.map((item: any) => {
          let count = 0;
          if (item === 'SELLER_ID') {
            count = 3;
          } else if (
            [
              LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
              LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
              LenderDocumentTypeEnum.FACE
            ].includes(item) &&
            inspectionDetailData.documentTypes.includes('SELLER_ID')
          ) {
            count = 0;
          } else if (
            ['PPSR', 'NEVDIS', 'RAV', 'GLASSES', 'SAFETY_CERT'].includes(item)
          ) {
            count = 0;
          } else {
            count = 1;
          }
          return count;
        }),
        (result, value) => {
          return result + value;
        },
        0
      )
    };
    setCountOfAll(totalObj);
  }, [activeStep, data]);
  const location = useLocation();
  const countOfDoneElements = (elements: any, index: number) => {
    if (location.pathname.includes('/demo/inspection')) {
      return _reduce(
        DOCUMENT_LIST[index],
        (result, value) => {
          if (
            inspectionDetailData.inspectionChecks[
              value as keyof typeof inspectionDetailData.inspectionChecks
            ].status === 'complete'
          ) {
            result.done += 1;
          }
          result.total = DOCUMENT_LIST[index].length;
          return result;
        },
        { done: 0, total: 0 }
      );
    } else {
      const docTypesDefined =
        index === 0
          ? [
              LenderDocumentTypeEnum.SELLER_ID,
              LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
              LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
              LenderDocumentTypeEnum.FACE
            ]
          : index === 2
          ? ['REGO_PAPERS']
          : DOCUMENT_LIST[index];
      return _reduce(
        _intersection(inspectionDetailData.documentTypes, docTypesDefined),
        (result, item) => {
          if (index === 0 && docTypesDefined.includes('SELLER_ID')) {
            return {
              done: _uniqBy(inspectionDetailData.files, 'documentType').filter(
                (file: any) =>
                  [
                    LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
                    LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
                    LenderDocumentTypeEnum.FACE
                  ].includes(file.documentType)
              ).length,
              total: 3
            };
          } else {
            const file: any = _uniqBy(
              inspectionDetailData.files,
              'documentType'
            ).some(
              (f: any) =>
                (f.documentType === item &&
                  [
                    'VIN',
                    'REGO_PAPERS',
                    'REGO_PAPERS',
                    'BANK_STATEMENTS'
                  ].includes(f.documentType) &&
                  f.fields2) ||
                (f.documentType === item &&
                  ![
                    'VIN',
                    'REGO_PAPERS',
                    'REGO_PAPERS',
                    'BANK_STATEMENTS'
                  ].includes(f.documentType))
            );
            return {
              done: file ? result.done + 1 : result.done,
              total: result.total + 1
            };
          }
        },
        { done: 0, total: 0 }
      );
    }
  };
  const registrationPaperStatus =
    inspectionDetailData.files.filter(
      (item: any) => item.documentType === 'REGO_PAPERS'
    ).length > 0
      ? true
      : false;
  const flagStatus = (item: any, index: number) => {
    //loop all the security checks of current item(tab)
    // 0 for blank, 1 for success and 2 for someone warning or failed
    const securityCheckResult = _reduce(
      SECURITY_CHECK_LIST[parseInt(item.value) - 1],
      (result: any, key: any) => {
        const items: { [key: string]: any } =
          inspectionDetailData.inspectionChecks;
        const documentObj: any =
          items &&
          (item.value === '3' && key === 'expiry'
            ? items['registrationExpiry']
            : item.value === '3' && key === 'registrationAuthenticity'
            ? items['regoCertificate']
            : item.value === '4'
            ? items['bankStatement']
            : items[key]);
        const type = documentObj && documentObj['type'];
        const isNotMatch =
          item.value === '2' &&
          key === 'vin' &&
          [
            items?.vin?.licencePlateMatch,
            items?.licencePlate?.vinMatch
          ].includes('DIFFERENT');
        const status =
          (documentObj && type === 'VEHICLE_VIN'
            ? isNotMatch
              ? InspectionSecurityStatusEnum.discrepancyFound
              : items?.vin?.licencePlateMatch ===
                  InspectionSecurityStatusEnum.match &&
                items?.licencePlate?.vinMatch ===
                  InspectionSecurityStatusEnum.match
              ? InspectionSecurityStatusEnum.match
              : [items?.vin?.status, items?.licencePlate?.status].includes(
                  InspectionSecurityStatusEnum.noRecord
                )
              ? InspectionSecurityStatusEnum.notFound
              : InspectionSecurityStatusEnum.notPerformed
            : ['REGO_CERTIFICATE', 'BANK_STATEMENT'].includes(type) &&
              [
                RegoSecurityCheckEnum.REGO_SECURITY_DATA_EDIT,
                BankSecurityCheckEnum.BANK_SECURITY_DATA_EDIT
              ].includes(key)
            ? documentObj.dataEditsStatus
            : type === 'NEVDIS_REGISTRATION' &&
              documentObj.status ===
                InspectionSecurityStatusEnum.notPerformed &&
              [items?.vin?.status, items?.licencePlate?.status].includes(
                InspectionSecurityStatusEnum.noRecord
              )
            ? InspectionSecurityStatusEnum.notFound
            : documentObj?.status) || InspectionSecurityStatusEnum.notPerformed;
        const flag =
          key === NevdisSecurityCheckEnum.NEVDIS_SECURITY_RAV &&
          status === InspectionSecurityStatusEnum.noRecord
            ? 'success_light'
            : ['PPSR_ENCUMBRANCE'].includes(type) &&
              status === InspectionSecurityStatusEnum.reportFound
            ? 'warning_light'
            : ['PPSR_ENCUMBRANCE'].includes(type) &&
              status === InspectionSecurityStatusEnum.noRecord
            ? 'success_light'
            : ['RAV_COMPLIANCE'].includes(type) &&
              status === InspectionSecurityStatusEnum.reportFound
            ? 'success_light'
            : ['RAV_COMPLIANCE'].includes(type) &&
              status === InspectionSecurityStatusEnum.noReport
            ? 'info_light'
            : type === 'NEVDIS_REGISTRATION' &&
              item.value !== '3' &&
              status === InspectionSecurityStatusEnum.reportFound &&
              documentObj.regoStatus ===
                InspectionSecurityStatusEnum.registered.toLocaleUpperCase()
            ? 'success_light'
            : type === 'NEVDIS_REGISTRATION' &&
              item.value !== '3' &&
              status === InspectionSecurityStatusEnum.noReport
            ? 'info_light'
            : type === 'LICENSE_EDITS' &&
              status === InspectionSecurityStatusEnum.failed
            ? 'warning_light'
            : item.value === '3' &&
              registrationPaperStatus &&
              type === 'NEVDIS_REGISTRATION'
            ? INSPECTION_SECURITY_CHECK_VARIANT[
                InspectionSecurityStatusEnum.discrepancyFound
              ]
            : INSPECTION_SECURITY_CHECK_VARIANT[
                status as InspectionSecurityStatusEnum
              ];
        return [result, flag].includes('info_light')
          ? 'info_light'
          : result === 'success_light' && flag === 'success_light'
          ? 'success_light'
          : 'warning_light';
      },
      'success_light'
    );
    return securityCheckResult === 'info_light'
      ? 0
      : securityCheckResult === 'warning_light'
      ? 2
      : 1;
  };
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollToItem = (index: number) => {
    // Get the item element by index
    if (isMobile) {
      const itemElement = containerRef.current?.children[index] as HTMLElement;
      // Scroll the container to the item element
      itemElement?.scrollIntoView({ behavior: 'smooth', inline: 'start' });
    }
  };
  return (
    <>
      <div className="xl:flex w-full">
        <div className="flex flex-col min-h-[160px] detail-box justify-start items-center xl:hidden">
          <SemiCircleProgress
            percentage={
              parseFloat((countOfAll.done / countOfAll.total).toFixed(2)) * 100
            }
            size={{
              width: 200,
              height: 200
            }}
            strokeWidth={10}
            bgStrokeColor={'#EAECF0'}
            hasBackground={true}
            strokeColor={'#F63D68'}
          />
          <div className="mt-[-120px] flex flex-col justify-center items-center">
            <p style={{ fontSize: 12, color: '#475467' }}>Progress</p>
            <p
              style={{
                fontSize: 16,
                color: '#101828'
              }}>{`${countOfAll.done} of ${countOfAll.total}`}</p>
          </div>
        </div>
        <div
          ref={containerRef}
          className="xl:flex-shrink-0 flex flex-row xl:flex-col xl:flex-col lg:min-w-[170px] xl:w-full overflow-auto mb-4 items-center">
          {DETAIL_VIEW_ITEMS.map((item, index) => {
            const countObj = countOfDoneElements(data[item.keyPath], index);
            const showFlg = () => {
              switch (item.keyPath) {
                case 'driverLicense':
                  return (
                    _intersection(inspectionDetailData.documentTypes, [
                      LenderDocumentTypeEnum.SELLER_ID,
                      LenderDocumentTypeEnum.DRIVER_LICENCE_FRONT,
                      LenderDocumentTypeEnum.DRIVER_LICENCE_BACK,
                      LenderDocumentTypeEnum.FACE
                    ]).length > 0
                  );
                case 'vehicle':
                  return (
                    _intersection(inspectionDetailData.documentTypes, [
                      LenderDocumentTypeEnum.ASSET_BACK,
                      LenderDocumentTypeEnum.ASSET_FRONT,
                      LenderDocumentTypeEnum.ASSET_LEFT,
                      LenderDocumentTypeEnum.ASSET_RIGHT,
                      LenderDocumentTypeEnum.VIN,
                      LenderDocumentTypeEnum.REGO_PLATE,
                      LenderDocumentTypeEnum.ODOMETER,
                      LenderDocumentTypeEnum.NEVDIS
                    ]).length > 0
                  );
                case 'regoPaper':
                  return inspectionDetailData.documentTypes.includes(
                    LenderDocumentTypeEnum.REGO_PAPERS
                  );
                case 'bank':
                  return inspectionDetailData.documentTypes.includes(
                    LenderDocumentTypeEnum.BANK_STATEMENTS
                  );
                case 'cert':
                  return (
                    inspectionDetailData.documentTypes.includes(
                      LenderDocumentTypeEnum.ROADWORTHY_CERT
                    ) ||
                    inspectionDetailData.documentTypes.includes(
                      LenderDocumentTypeEnum.SAFETY_CERT
                    )
                  );
                default:
                  return false;
              }
            };
            return (
              <div
                key={index}
                className={clsx('flow w-full', !showFlg() && 'hidden')}>
                <StatusListItem
                  value={item.value}
                  label={
                    item.value === '5' &&
                    inspectionDetailData.documentTypes.includes(
                      LenderDocumentTypeEnum.ROADWORTHY_CERT
                    )
                      ? item.shortLabel
                      : item.value === '5' &&
                        inspectionDetailData.documentTypes.includes(
                          LenderDocumentTypeEnum.SAFETY_CERT
                        )
                      ? item.shortLabel2
                      : item.shortLabel
                  }
                  onClick={() => {
                    scrollToItem(index);
                    setActiveStep(item.value);
                  }}
                  progressLabel={countObj.done + '/' + countObj.total}
                  active={activeStep === item.value}
                  success={
                    countObj.done === countObj.total &&
                    flagStatus(item, index) === 1
                  }
                  hideIcon={
                    data.status === InspectionStatusEnum.Initiated ||
                    countObj.done < countObj.total
                  }
                  className="justify-between"
                />
              </div>
            );
          })}
          <div className="hidden xl:block px-6 py-8 gap-6">
            <CircularProgressbarWithChildren
              value={countOfAll.done}
              maxValue={countOfAll.total}
              strokeWidth={10}
              styles={buildStyles({
                pathColor: '#F63D68',
                textColor: '#101828',
                trailColor: '#EAECF0'
              })}>
              <div style={{ fontSize: 12, color: '#475467' }}>PROGRESS</div>
              <div style={{ fontSize: 20, color: '#101828' }}>
                <strong>{`${countOfAll.done} of ${countOfAll.total}`}</strong>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </div>
      </div>
    </>
  );
};
export default DetailedInfo;
