import { Button } from '@material-tailwind/react';
import { useInspectionDetailStore } from '@stores';
import {
  NevdisSecurityCheckEnum,
  DocumentFormType,
  InspectionSecurityStatusEnum,
  InspectionVehicleDetailsSection
} from '@types';

import {
  DETAILS_VIEW_REGISTRATION,
  PPSR_VIEW,
  STOLEN_VIEW,
  WRITTEN_OFF_VIEW,
  ADDRESS_VIEW,
  GRANTOR_INDIVIDUAL_VIEW,
  GRANTOR_ORG_VIEW,
  SECURED_INDIVIDUAL_PARTIES_VIEW,
  SECURED_ORG_PARTIES_VIEW,
  COMPLIANCE_VIEW,
  SELLER_SUBMITTED_VIEW,
  NEVDIS_RETRIEVE_VIEW
} from '@constants';
import _get from 'lodash/get';
import _map from 'lodash/map';
import _merge from 'lodash/merge';
interface IProps {
  type: DocumentFormType;
  status: InspectionSecurityStatusEnum;
  data: any;
}

const NevdisCheckCard = ({ type, status, data }: IProps) => {
  const { isCollapsed, setCollapse } = useInspectionDetailStore();
  const states = {
    ACT: 'Australian Capital Territory',
    NSW: 'New South Wales',
    NT: 'Northern Territory',
    QLD: 'Queensland',
    SA: 'South Australia',
    TAS: 'Tasmania',
    VIC: 'Victoria',
    WA: 'Western Australia'
  };
  const handleClose = () => {
    setCollapse(true);
  };
  if (
    [
      InspectionSecurityStatusEnum.notPerformed,
      InspectionSecurityStatusEnum.notFound
    ].includes(status)
  )
    return null;
  const vinChecksData = data.licencePlate &&
    data.vin && {
      userRego: data.licencePlate.userRego,
      userState: data.licencePlate.userState,
      userVin: data.vin.userVin,
      nevdis: _merge(data.vin.nevdis, data.licencePlate.nevdis),
      vinNevdis: {
        ...data.vin.nevdis
      },
      regoNevdis: {
        ...data.licencePlate.nevdis
      }
    };
  switch (type) {
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_RAV:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                <p className="font-semibold text-grey-800">
                  Compliance Details
                </p>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  {_map(
                    COMPLIANCE_VIEW,
                    (row: any, index: number) =>
                      _get(data.result, row.keyPath) && (
                        <div
                          key={index}
                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                          <p className="text-grey-500 text-sm font-medium leading-5">
                            {row.displayName}
                          </p>
                          <p className="text-sm font-medium text-grey-950 text-right">
                            {_get(data.result, row.keyPath)}
                          </p>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            {!isCollapsed && (
              <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                <Button
                  className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_PPSR:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                <div className="flex flex-col border-b border-grey-200 w-full">
                  {_map(data.ppsrData, (ppsrData: any, dIndex: number) => {
                    return (
                      <>
                        <p
                          className="font-semibold text-grey-800 py-6 border-b first:pt-0 "
                          key={'ppsrTitle' + dIndex}>
                          Registration {dIndex + 1} / {data.ppsrData.length}
                        </p>
                        {_map(PPSR_VIEW, (row: any, index: number) => {
                          if (_get(ppsrData, row.keyPath) && !row.type)
                            return (
                              <div
                                key={'ppsrData' + index}
                                className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                                <p className="text-grey-500 text-sm font-medium leading-5">
                                  {row.displayName}
                                </p>
                                <p className="text-sm font-medium text-grey-950 text-right">
                                  {_get(ppsrData, row.keyPath)}
                                </p>
                              </div>
                            );
                          else if (
                            _get(ppsrData, row.keyPath) &&
                            row.type === 'array'
                          ) {
                            const dataRow = _get(ppsrData, row.keyPath);
                            return (
                              _get(ppsrData, row.keyPath).length > 0 && (
                                <>
                                  <p
                                    className="font-semibold py-3.5 border-b border-grey-200 text-grey-800"
                                    key={'ppsrData' + index}>
                                    {row.displayName}
                                  </p>
                                  {_map(dataRow, (item: any, i: number) => {
                                    if (dataRow.length === 0) return null;
                                    return _map(
                                      row.key === 'grantors' &&
                                        item.grantorType === 'Individual'
                                        ? GRANTOR_INDIVIDUAL_VIEW
                                        : row.key !== 'grantors' &&
                                          item.grantorType === 'Individual'
                                        ? SECURED_INDIVIDUAL_PARTIES_VIEW
                                        : row.key === 'grantors' &&
                                          item.grantorType === 'Organisation'
                                        ? GRANTOR_ORG_VIEW
                                        : SECURED_ORG_PARTIES_VIEW,
                                      (subRow: any, subIndex: number) => (
                                        <div
                                          key={
                                            (row.key === 'grantors'
                                              ? 'grantors_'
                                              : 'secured_parties_') +
                                            dIndex +
                                            '_' +
                                            i +
                                            '_' +
                                            subIndex
                                          }
                                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                                          <p className="text-grey-500 text-sm font-medium leading-5">
                                            {subRow.displayName}
                                          </p>
                                          <p className="text-sm font-medium text-grey-950 text-right">
                                            {_get(item, subRow.keyPath)}
                                          </p>
                                        </div>
                                      )
                                    );
                                  })}
                                </>
                              )
                            );
                          } else if (
                            _get(data, row.keyPath) &&
                            row.type === 'object'
                          ) {
                            return (
                              <>
                                <p
                                  className="font-semibold border-b border-grey-200 py-3.5 text-grey-800"
                                  key={'addresseeTitle_' + dIndex}>
                                  {row.displayName}
                                </p>
                                {_map(
                                  ADDRESS_VIEW,
                                  (subRow: any, subIndex: number) => {
                                    const dataRow = _get(data, row.keyPath);
                                    return (
                                      _get(dataRow, subRow.keyPath) && (
                                        <div
                                          key={
                                            'addressee_' +
                                            dIndex +
                                            '_' +
                                            subIndex
                                          }
                                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                                          <p className="text-grey-500 text-sm font-medium leading-5">
                                            {subRow.displayName}
                                          </p>
                                          <p className="text-sm font-medium text-grey-950 text-right">
                                            {_get(dataRow, subRow.keyPath)}
                                          </p>
                                        </div>
                                      )
                                    );
                                  }
                                )}
                              </>
                            );
                          }
                        })}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            {!isCollapsed && (
              <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                <Button
                  className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_REGISTRATION:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                <p className="font-semibold text-grey-800">
                  Registration Details
                </p>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  {_map(
                    DETAILS_VIEW_REGISTRATION,
                    (row: any, index: number) =>
                      _get(data, row.keyPath) && (
                        <div
                          key={index}
                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                          <p className="text-grey-500 text-sm font-medium leading-5">
                            {row.displayName}
                          </p>
                          <p className="text-sm font-medium text-grey-950 text-right">
                            {row.key === 'regoStatus' &&
                            status === 'REGISTRATION_EXPIRED'
                              ? 'Registration Expired'
                              : _get(data, row.keyPath)}
                          </p>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            {!isCollapsed && (
              <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                <Button
                  className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_STOLEN:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                {_map(data.stolenDetails, (item, i) => (
                  <>
                    <p className="font-semibold text-grey-800" key={i}>
                      Stolen report details ({i + 1}/{data.stolenDetails.length}
                      )
                    </p>
                    <div className="flex flex-col border-y border-grey-200 w-full">
                      {_map(
                        STOLEN_VIEW,
                        (row: any, index: number) =>
                          _get(item, row.keyPath) && (
                            <div
                              key={index}
                              className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                              <p className="text-grey-500 text-sm font-medium leading-5">
                                {row.displayName}
                              </p>
                              <p className="text-sm font-medium text-grey-950 text-right">
                                {_get(item, row.keyPath)}
                              </p>
                            </div>
                          )
                      )}
                    </div>
                  </>
                ))}
              </div>
            </div>
            {!isCollapsed && (
              <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                <Button
                  className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_WRITTEN_OFF:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                {_map(data.writtenOffDetails, (item, i) => (
                  <>
                    <p className="font-semibold text-grey-800" key={i}>
                      Written-off Report Details ({i + 1}/
                      {data.writtenOffDetails.length})
                    </p>
                    <div className="flex flex-col border-y border-grey-200 w-full">
                      {_map(
                        WRITTEN_OFF_VIEW,
                        (row: any, index: number) =>
                          _get(item, row.keyPath) && (
                            <div
                              key={index}
                              className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                              <p className="text-grey-500 text-sm font-medium leading-5">
                                {row.displayName}
                              </p>
                              <p className="text-sm font-medium text-grey-950 text-right">
                                {_get(item, row.keyPath)}
                              </p>
                            </div>
                          )
                      )}
                    </div>
                  </>
                ))}
              </div>
              {!isCollapsed && (
                <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                  <Button
                    className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                    variant="outlined"
                    onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_VIN:
      return (
        <div className="flex flex-col p-6 gap-6 border-t h-full">
          <div className="flex gap-x-2 h-full flex-col justify-between">
            <div className="flex flex-col w-full gap-y-6">
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                <p className="font-semibold text-grey-800">
                  Seller submitted details
                </p>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  {_map(
                    SELLER_SUBMITTED_VIEW,
                    (row: any, index: number) =>
                      _get(vinChecksData, row.keyPath) && (
                        <div
                          key={index}
                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                          <p className="text-grey-500 text-sm font-medium leading-5">
                            {row.displayName}
                          </p>
                          <p className="text-sm font-medium text-grey-950 text-right">
                            {row.type && row.type === 'STATE'
                              ? _get(states, _get(vinChecksData, row.keyPath))
                              : _get(vinChecksData, row.keyPath)}
                          </p>
                        </div>
                      )
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                <p className="font-semibold text-grey-800">
                  NEVDIS registration details
                </p>
                <div className="flex flex-col border-y border-grey-200 w-full">
                  {_map(
                    NEVDIS_RETRIEVE_VIEW,
                    (row: any, index: number) =>
                      _get(vinChecksData.nevdis, row.keyPath) && (
                        <div
                          key={index}
                          className="grid grid-cols-2 py-3.5 border-b border-grey-200 last:border-none">
                          <p className="text-grey-500 text-sm font-medium leading-5">
                            {row.displayName}
                          </p>
                          <p className="text-sm font-medium text-grey-950 text-right">
                            {row.type && row.type === 'STATE'
                              ? _get(
                                  states,
                                  _get(vinChecksData.nevdis, row.keyPath)
                                )
                              : _get(vinChecksData.nevdis, row.keyPath)}
                          </p>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
            {!isCollapsed && (
              <div className="sticky lg:w-full lg:justify-end bottom-0 right-0 w-full bg-white flex flex-col md:flex-row gap-4 mt-6">
                <Button
                  className="w-full font-semibold bg-white border-grey-300 text-grey-700 text-base capitalize"
                  variant="outlined"
                  onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      );
    case NevdisSecurityCheckEnum.NEVDIS_SECURITY_PHOTO:
      return (
        <div className="p-6 gap-6 border-t h-full">
          <div className="bg-[#fffaeb] p-4 rounded-sm ">
            Authentik8’s artificial intelligence detected the following issues
            when analysing the vehicle photos.
          </div>
          {/* <div className="border-t mt-6">
            <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
              <div className="mt-4">
                <p className="font-semibold text-[#1D2939] text-base">
                  Seller submitted details
                </p>
                <p className="text-sm text-[#0C111D] mt-2">
                  The vehicle in the photos is a Toyota with the number plate
                  YPS08T, whereas the NEVDIS details specify a Subaru Forester
                  with the number plate YPU90K.
                </p>
              </div>
            </div>
          </div> */}
          {_map(data.photos, (photo, index) => {
            if (photo.status === 'WARNING') {
              return (
                <div className="border-t mt-6" key={index}>
                  <div className="flex flex-col gap-y-6 h-full justify-start items-start w-full">
                    <div className="mt-4">
                      <p className="font-semibold text-[#1D2939] text-base">
                        {
                          InspectionVehicleDetailsSection[
                            photo.documentType as keyof typeof InspectionVehicleDetailsSection
                          ]
                        }
                      </p>
                      <p className="text-sm text-[#0C111D] mt-2">
                        {photo.reason}
                      </p>
                    </div>
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      );
    default:
      return <> </>;
  }
};
export default NevdisCheckCard;
