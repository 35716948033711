import React, { useState, useEffect } from 'react';
import { Chip, ChipProps } from '@material-tailwind/react';
import { DOCUMENT_TYPE } from '@constants';
import { MultiSelect } from 'react-multi-select-component';
import { ReactComponent as ArrowDownIcon } from '@assets/icons/arrow-down.svg';
import { useNavigate } from 'react-router-dom';
import _intersection from 'lodash/intersection';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import { LenderDocumentTypeEnum } from '@types';
import { useForm } from 'react-hook-form';
import Button from '@components/forms/Button';
import { useInspectionDetailStore } from '@stores';
import Loader from '@components/widgets/Loader';

type Props = {
  onPrevious: () => void;
  onClose: () => void;
  selectedLender: any;
  onNext: () => void;
};

const TextChip = (props: ChipProps) => {
  return (
    <Chip
      color="gray"
      variant="outlined"
      className="rounded-full border-grey-200 bg-grey-50 text-grey-700 normal-case w-fit text-sm font-medium"
      {...props}
    />
  );
};
const DocumentsStep: React.FC<Props> = ({
  onPrevious,
  onClose,
  onNext,
  selectedLender
}) => {
  const { handleSubmit } = useForm();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { inspectionDetailData, setDocumentTypes, setAssetType, setDraft } =
    useInspectionDetailStore();
  const [fixedOptions, setFixOptions] = useState<string[]>([]);
  const [selectedDocuments, setSelected] = useState<string[]>([]);
  const [orgName, setOrgName] = useState<string>('');
  const [templateName, setTemplateName] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [availableOptions, setAvailableOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const removeItems = (key: any) => {
    const filters = selectedDocuments.filter((item) => item !== key);
    setSelected(filters);
    const removeOptions = selectedOptions.filter((item) => item.value !== key);
    setSelectedOptions(removeOptions);
  };
  const onMultiSelectChange = (e: { label: string; value: string }[]) => {
    setSelectedOptions(e);
    const selectValues = e.map((item) => item.value);
    setSelected(selectValues);
  };
  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      const lender = localStorage.getItem(`lenders_${userId}` as any);
      const broker = localStorage.getItem(`lenderBrokers_${userId}` as any);
      if (lender) {
        setOrgName(JSON.parse(lender)[0].organisationName);
        const template = inspectionDetailData.lender.template;
        setTemplateName(template.name);
        if (template && template.documentTypes) {
          const fixed =
            inspectionDetailData.saleState === 'VIC'
              ? template.documentTypes.filter(
                  (doc: string) => doc !== LenderDocumentTypeEnum.SAFETY_CERT
                )
              : inspectionDetailData.saleState === 'QLD'
              ? template.documentTypes.filter(
                  (doc: string) =>
                    doc !== LenderDocumentTypeEnum.ROADWORTHY_CERT
                )
              : template.documentTypes.filter(
                  (doc: string) =>
                    doc !== LenderDocumentTypeEnum.SAFETY_CERT &&
                    doc !== LenderDocumentTypeEnum.ROADWORTHY_CERT
                );
          setFixOptions(fixed);
          setAssetType(template.assetType);
          setAvailableOptions(
            DOCUMENT_TYPE.filter(
              (item) => !template.documentTypes.includes(item.value)
            ).filter((item) => {
              if (inspectionDetailData.saleState === 'VIC') {
                return item.value !== LenderDocumentTypeEnum.SAFETY_CERT;
              } else if (inspectionDetailData.saleState === 'QLD') {
                return item.value !== LenderDocumentTypeEnum.ROADWORTHY_CERT;
              } else {
                return (
                  item.value !== LenderDocumentTypeEnum.SAFETY_CERT &&
                  item.value !== LenderDocumentTypeEnum.ROADWORTHY_CERT
                );
              }
            })
          );
        }
      } else if (selectedLender && broker) {
        const brokers = JSON.parse(broker) || [];
        const organisation = brokers.find(
          (broker: any) => broker.lenderOrganisationId === selectedLender
        );
        setOrgName(organisation.lenderOrganisationName);
        const template = inspectionDetailData.lender.template;
        setTemplateName(template.name);
        if (template && template.documentTypes) {
          const fixed =
            inspectionDetailData.saleState === 'VIC'
              ? template.documentTypes.filter(
                  (doc: string) => doc !== LenderDocumentTypeEnum.SAFETY_CERT
                )
              : inspectionDetailData.saleState === 'QLD'
              ? template.documentTypes.filter(
                  (doc: string) =>
                    doc !== LenderDocumentTypeEnum.ROADWORTHY_CERT
                )
              : template.documentTypes.filter(
                  (doc: string) =>
                    doc !== LenderDocumentTypeEnum.SAFETY_CERT &&
                    doc !== LenderDocumentTypeEnum.ROADWORTHY_CERT
                );
          setFixOptions(fixed);
          setAssetType(template.assetType);
          setAvailableOptions(
            DOCUMENT_TYPE.filter(
              (item) => !template.documentTypes.includes(item.value)
            ).filter((item) => {
              if (inspectionDetailData.saleState === 'VIC') {
                return item.value !== LenderDocumentTypeEnum.SAFETY_CERT;
              } else if (inspectionDetailData.saleState === 'QLD') {
                return item.value !== LenderDocumentTypeEnum.ROADWORTHY_CERT;
              } else {
                return (
                  item.value !== LenderDocumentTypeEnum.SAFETY_CERT &&
                  item.value !== LenderDocumentTypeEnum.ROADWORTHY_CERT
                );
              }
            })
          );
        }
      } else {
        navigate('/');
      }
    } else {
      setFixOptions(inspectionDetailData.documentTypes);
      setAvailableOptions(
        DOCUMENT_TYPE.filter(
          (item) => !inspectionDetailData.documentTypes.includes(item.value)
        )
      );
    }
  }, []);
  const ArrowRenderer = ({ expanded }: { expanded: any }) => (
    <div
      className={clsx(
        'grid place-items-center absolute top-2/4 right-2 pt-px w-5 h-5 text-blue-gray-400 rotate-0 -translate-y-2/4 transition-all',
        expanded && 'rotate-180'
      )}>
      <ArrowDownIcon />
    </div>
  );

  ArrowRenderer.propTypes = {
    expanded: PropTypes.bool
  };
  const onSaveClick = () => {
    setDocumentTypes(
      Array.from(new Set([...fixedOptions, ...selectedDocuments]))
    );
    setDraft(true);
    onClose();
  };
  const onSubmit = () => {
    setDocumentTypes(
      Array.from(new Set([...fixedOptions, ...selectedDocuments]))
    );
    onNext();
  };
  if (isLoading) return <Loader />;
  return (
    <form>
      <h3 className="text-xl font-semibold text-grey-900 mb-6">Documents</h3>

      <div className="mb-4 pb-4 border-b">
        <p className="font-medium text-grey-900">{templateName}</p>
        <p className="font-normal text-grey-600 mt-3 text-sm">
          The following documents are required by {orgName} for the selected
          inspection template.
        </p>
        <p className="font-normal text-grey-600 mt-3 text-sm">
          Add additional documents if required.
        </p>
      </div>

      {_intersection(fixedOptions, [LenderDocumentTypeEnum.SELLER_ID]).length >
        0 && (
        <div className="mb-8">
          <p className="text-grey-700 font-semibold mb-3 text-sm">
            Seller Identification
          </p>
          <div className="flex gap-2 flex-wrap">
            {_intersection(fixedOptions, [
              LenderDocumentTypeEnum.SELLER_ID
            ]).map((item: any) => {
              const doc = _find(DOCUMENT_TYPE, ['value', item]);
              return <TextChip key={doc?.value} value={doc?.label} />;
            })}
          </div>
        </div>
      )}

      {_intersection(fixedOptions, [
        LenderDocumentTypeEnum.ASSET_BACK,
        LenderDocumentTypeEnum.ASSET_FRONT,
        LenderDocumentTypeEnum.ASSET_LEFT,
        LenderDocumentTypeEnum.ASSET_RIGHT,
        LenderDocumentTypeEnum.VIN,
        LenderDocumentTypeEnum.REGO_PLATE,
        LenderDocumentTypeEnum.ODOMETER
      ]).length > 0 && (
        <div className="mb-8">
          <p className="text-grey-700 font-semibold mb-3 text-sm">Asset</p>
          <div className="flex gap-2 flex-wrap">
            {_intersection(fixedOptions, [
              LenderDocumentTypeEnum.ASSET_BACK,
              LenderDocumentTypeEnum.ASSET_FRONT,
              LenderDocumentTypeEnum.ASSET_LEFT,
              LenderDocumentTypeEnum.ASSET_RIGHT,
              LenderDocumentTypeEnum.VIN,
              LenderDocumentTypeEnum.REGO_PLATE,
              LenderDocumentTypeEnum.ODOMETER
            ]).map((item: any) => {
              const doc = _find(DOCUMENT_TYPE, ['value', item]);
              return <TextChip key={doc?.value} value={doc?.label} />;
            })}
          </div>
        </div>
      )}
      {_intersection(fixedOptions, [LenderDocumentTypeEnum.REGO_PAPERS])
        .length > 0 && (
        <div className="mb-8">
          <p className="text-grey-700 font-semibold mb-3 text-sm">
            Registration Certificate
          </p>
          <div className="flex gap-2 flex-wrap">
            {_intersection(fixedOptions, [
              LenderDocumentTypeEnum.REGO_PAPERS
            ]).map((item: any) => {
              const doc = _find(DOCUMENT_TYPE, ['value', item]);
              return <TextChip key={doc?.value} value={doc?.label} />;
            })}
          </div>
        </div>
      )}
      {_intersection(fixedOptions, [LenderDocumentTypeEnum.BANK_STATEMENTS])
        .length > 0 && (
        <div className="mb-8">
          <p className="text-grey-700 font-semibold mb-3 text-sm">
            Bank Statement
          </p>
          <div className="flex gap-2 flex-wrap">
            {_intersection(fixedOptions, [
              LenderDocumentTypeEnum.BANK_STATEMENTS
            ]).map((item: any) => {
              const doc = _find(DOCUMENT_TYPE, ['value', item]);
              return <TextChip key={doc?.value} value={doc?.label} />;
            })}
          </div>
        </div>
      )}
      {_intersection(fixedOptions, [
        LenderDocumentTypeEnum.ROADWORTHY_CERT,
        LenderDocumentTypeEnum.SAFETY_CERT
      ]).length > 0 && (
        <div className="mb-8">
          <p className="text-grey-700 font-semibold mb-3 text-sm">
            {LenderDocumentTypeEnum.ROADWORTHY_CERT
              ? 'Roadworthy Certificate'
              : 'Safety Certificate'}
          </p>
          <div className="flex gap-2 flex-wrap">
            {_intersection(fixedOptions, [
              LenderDocumentTypeEnum.ROADWORTHY_CERT,
              LenderDocumentTypeEnum.SAFETY_CERT
            ]).map((item: any) => {
              const doc = _find(DOCUMENT_TYPE, ['value', item]);
              return <TextChip key={doc?.value} value={doc?.label} />;
            })}
          </div>
        </div>
      )}
      {_intersection(fixedOptions, [
        LenderDocumentTypeEnum.PPSR,
        LenderDocumentTypeEnum.RAV,
        LenderDocumentTypeEnum.NEVDIS,
        LenderDocumentTypeEnum.GLASSES
      ]).length > 0 && (
        <div className="mb-8">
          <p className="text-grey-700 font-semibold mb-3 text-sm">
            External Verifications
          </p>
          <div className="flex gap-2 flex-wrap">
            {_intersection(fixedOptions, [
              LenderDocumentTypeEnum.PPSR,
              LenderDocumentTypeEnum.RAV,
              LenderDocumentTypeEnum.NEVDIS,
              LenderDocumentTypeEnum.GLASSES
            ]).map((item: any) => {
              const doc = _find(DOCUMENT_TYPE, ['value', item]);
              return <TextChip key={doc?.value} value={doc?.label} />;
            })}
          </div>
        </div>
      )}
      {availableOptions.length > 0 && (
        <div>
          <div>Add additional documents</div>
          <MultiSelect
            options={availableOptions}
            value={selectedOptions}
            className="border-grey-300 mb-1.5 shadow-sm rounded-md w-full !h-10 text-sm text-grey-400 ring-1 ring-grey-900/10 hover:ring-grey-300 dark:bg-grey-800 dark:highlight-white/5 dark:hover:bg-grey-700"
            ArrowRenderer={ArrowRenderer}
            onChange={onMultiSelectChange}
            labelledBy="Add additional documents"
          />
          <div className="flex gap-2 flex-wrap">
            {selectedOptions.map((tag) => (
              <TextChip
                key={tag.value}
                value={tag.label}
                onClose={() => removeItems(tag?.value)}
              />
            ))}
          </div>
        </div>
      )}
      <div className="flex justify-between border-t border-t-grey-200 pt-5 mt-8 gap-3">
        <div className="flex flex-row gap-2">
          <Button
            className="bg-white text-grey-700 border-grey-300 !text-sm"
            variant="outlined"
            onClick={onPrevious}>
            Previous
          </Button>
        </div>
        <div className="flex flex-row gap-2">
          <Button onClick={onSubmit} className="text-white bg-primary !text-sm">
            Next
          </Button>
        </div>
      </div>
    </form>
  );
};

export default DocumentsStep;
