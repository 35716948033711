import { Outlet } from 'react-router-dom';
import SellerHeader from './SellerHeader';
import { useSellerStore } from '@stores';
import SellerFooter from './SellerFooter';
import { isDesktop } from 'react-device-detect';

const SellerLayout = () => {
  const { isHome } = useSellerStore();
  return (
    <div className="w-full flex flex-col bg-grey-50">
      {(isHome || isDesktop) && <SellerHeader />}
      <div className="flex-auto flex flex-col bg-grey-50 relative no-scrollbar overflow-y-hidden">
        <Outlet />
      </div>
      {(isHome || isDesktop) && <SellerFooter />}
    </div>
  );
};

export default SellerLayout;
