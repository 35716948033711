import { useEffect, useState } from 'react';
import { DETAIL_VIEW_ITEMS } from '@constants';
import { ReactComponent as NoDriverLicense } from '@assets/icons/no_license_data.svg';
import { ReactComponent as NoVehicle } from '@assets/icons/no-vehicle.svg';
import { ReactComponent as NoRegistration } from '@assets/icons/no-registration.svg';
import { ReactComponent as NoBank } from '@assets/icons/no-bank.svg';
import { ReactComponent as NoCert } from '@assets/icons/no-cert.svg';
import DriverLicense from '@pages/Inspections/components/DetailedInfo/DriverLicense';
import VehicleDetails from '@pages/Inspections/components/DetailedInfo/VehicleDetails';
import RegoPaper from '@pages/Inspections/components/DetailedInfo/RegoPaper';
import BankStatement from '@pages/Inspections/components/DetailedInfo/BankStatement';
import Cert from '@pages/Inspections/components/DetailedInfo/Cert';
import Documents from '@pages/Inspections/components/Documents';
import {
  InspectionDriverLicenseSection,
  InspectionVehicleDetailsSection,
  InspectionRegoPapersSection,
  InspectionRoadworthyCertSection,
  InspectionBankSection,
  InspectionSecurityStatusEnum
} from '@types';
import { SECURITY_CHECK_LIST } from '@constants';
import SecurityCheck from './SecurityCheck';
import clsx from 'clsx';
import { useInspectionDetailStore } from '@stores';
import _groupBy from 'lodash/groupBy';
import _maxBy from 'lodash/maxBy';
import _flatMap from 'lodash/flatMap';
type Props = {
  activeStep: string;
};
const InspectionMainInfo = ({ activeStep }: Props) => {
  const docTypes = [
    InspectionDriverLicenseSection,
    InspectionVehicleDetailsSection,
    InspectionRegoPapersSection,
    InspectionBankSection,
    InspectionRoadworthyCertSection
  ];
  const { inspectionDetailData } = useInspectionDetailStore();
  const [items, setDocData] = useState(inspectionDetailData.inspectionChecks);
  const [registrationDetails, setRegistrationDetails] = useState<any>();
  const [bankDetails, setBankDetails] = useState<any>();
  const [showSecurityCheck, setShowSecurityCheck] = useState<boolean>(true);
  const getLatestFiles = (files: any) => {
    const groupedFiles = _groupBy(files, 'documentType');

    const latestFiles = _flatMap(groupedFiles, (group) =>
      _maxBy(group, 'createdTime')
    );

    return latestFiles;
  };
  useEffect(() => {
    switch (activeStep) {
      case '1':
        setDocData(inspectionDetailData.inspectionChecks);
        break;
      case '2':
        setDocData(inspectionDetailData.inspectionChecks);
        break;
      case '3':
        setDocData(inspectionDetailData.inspectionChecks);
        setRegistrationDetails(
          inspectionDetailData.inspectionChecks.regoCertificate
        );

        break;
      case '4':
        setDocData(inspectionDetailData.inspectionChecks);
        setBankDetails(inspectionDetailData.inspectionChecks.bankStatement);
        break;
      case '5':
        setDocData(inspectionDetailData.inspectionChecks);
        break;
      default:
    }
    setShowSecurityCheck(
      SECURITY_CHECK_LIST[parseInt(activeStep) - 1].length > 0
    );
  }, [activeStep, inspectionDetailData]);
  return (
    <>
      <div
        className={clsx(
          'grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-6 mt-6 gap-x-6 lg:gap-x-8 xl:gap-y-1 md:gap-y-1 w-full',
          activeStep === '5' &&
            !inspectionDetailData.inspectionChecks.ROADWORTHY_CERT &&
            '!mt-0'
        )}>
        <div
          className={clsx(
            'rounded-lg border border-grey-200 bg-grey-50 flex flex-col gap-6 xl:h-full  h-full md:min-h-[320px] col-span-6 lg:col-span-5 md:col-span-5',
            !showSecurityCheck ? 'xl:col-span-6' : 'xl:col-span-4',
            activeStep === '5' &&
              !inspectionDetailData.inspectionChecks.ROADWORTHY_CERT &&
              'hidden'
          )}>
          <div className="border-b p-4 text-lg">
            <h1 className="text-black-title font-semibold text-base">
              {
                DETAIL_VIEW_ITEMS.filter((item) => item.value === activeStep)[0]
                  .label
              }
            </h1>
          </div>
          <div className="flex w-full">
            {activeStep === '1' &&
              inspectionDetailData.inspectionChecks.licenseEdits.status !==
                InspectionSecurityStatusEnum.notPerformed &&
              inspectionDetailData.inspectionChecks.licenseEdits.status !==
                InspectionSecurityStatusEnum.notProvided && (
                <div className="grid grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xl:grid-cols-4 auto-cols-max px-4 gap-3 xl:gap-y-6 md:gap-y-6 w-full pb-6 ">
                  <DriverLicense
                    driver={
                      inspectionDetailData.inspectionChecks.licenseExpiry
                        .dateOfExpiry
                        ? {
                            ...inspectionDetailData.inspectionChecks
                              .licenseEdits,
                            dateOfExpiry: {
                              ocrValue:
                                inspectionDetailData.inspectionChecks
                                  .licenseExpiry.dateOfExpiry
                            }
                          }
                        : inspectionDetailData.inspectionChecks.licenseEdits
                    }
                  />
                </div>
              )}
            {activeStep === '1' &&
              inspectionDetailData.inspectionChecks.licenseEdits.status ===
                InspectionSecurityStatusEnum.notPerformed && (
                <div className="flex justify-center items-center h-full w-full flex-col gap-y-6 min-h-[240px]">
                  <NoDriverLicense />
                  <p className="text-grey-900 font-semibold text-center">
                    {"Driver's Licence Pending"}
                  </p>
                </div>
              )}
            {activeStep === '1' &&
              inspectionDetailData.inspectionChecks.licenseEdits.status ===
                InspectionSecurityStatusEnum.notProvided && (
                <div className="flex justify-center items-center h-full w-full flex-col gap-y-6 min-h-[240px]">
                  <NoDriverLicense />
                  <p className="text-grey-900 font-semibold text-center">
                    {"The seller declined to provide their driver's licence"}
                  </p>
                </div>
              )}
            {activeStep === '2' && (
              <>
                {inspectionDetailData.inspectionChecks.nevdisVehicleDetails &&
                inspectionDetailData.inspectionChecks.nevdisVehicleDetails
                  .status === InspectionSecurityStatusEnum.reportFound ? (
                  <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-1 xl:grid-cols-4 auto-cols-max px-4 gap-3 xl:gap-y-6 md:gap-y-6 w-full pb-6">
                    <VehicleDetails
                      vehicle={
                        inspectionDetailData.inspectionChecks
                          .nevdisVehicleDetails
                      }
                    />
                  </div>
                ) : (
                  <div className="flex justify-center items-center h-full w-full flex-col gap-y-6 min-h-[240px]">
                    <NoVehicle />
                    <p className="text-grey-900 font-semibold text-center">
                      {(inspectionDetailData.inspectionChecks
                        .nevdisVehicleDetails &&
                        inspectionDetailData.inspectionChecks
                          .nevdisVehicleDetails.status ===
                          InspectionSecurityStatusEnum.noRecord) ||
                      (inspectionDetailData.inspectionChecks.vin &&
                        inspectionDetailData.inspectionChecks.licencePlate &&
                        ([
                          InspectionSecurityStatusEnum.noRecord,
                          InspectionSecurityStatusEnum.notPerformed,
                          InspectionSecurityStatusEnum.error
                        ].includes(
                          inspectionDetailData.inspectionChecks.vin?.nevdis
                            ?.result
                        ) ||
                          inspectionDetailData.inspectionChecks.vin?.status ===
                            InspectionSecurityStatusEnum.notPerformed) &&
                        ([
                          InspectionSecurityStatusEnum.noRecord,
                          InspectionSecurityStatusEnum.error,
                          InspectionSecurityStatusEnum.notPerformed
                        ].includes(
                          inspectionDetailData.inspectionChecks.licencePlate
                            ?.nevdis?.result
                        ) ||
                          inspectionDetailData.inspectionChecks.licencePlate
                            ?.status ===
                            InspectionSecurityStatusEnum.notPerformed) &&
                        ![
                          inspectionDetailData.inspectionChecks.licencePlate
                            .status,
                          inspectionDetailData.inspectionChecks.vin.status,
                          inspectionDetailData.inspectionChecks
                            .nevdisVehicleDetails.status
                        ].every(
                          (i) => i === InspectionSecurityStatusEnum.notPerformed
                        ))
                        ? 'Cannot retrieve vehicle details'
                        : inspectionDetailData.inspectionChecks.vin &&
                          inspectionDetailData.inspectionChecks.licencePlate &&
                          (inspectionDetailData.inspectionChecks.vin
                            ?.licencePlateMatch ===
                            InspectionSecurityStatusEnum.discrepancyFound ||
                            inspectionDetailData.inspectionChecks.licencePlate
                              ?.vinMatch ===
                              InspectionSecurityStatusEnum.discrepancyFound)
                        ? 'The VIN or registration details provided by the seller do not match the NEVDIS registry'
                        : 'Vehicle Details Pending'}
                    </p>
                    {(inspectionDetailData.inspectionChecks
                      .nevdisVehicleDetails &&
                      inspectionDetailData.inspectionChecks.nevdisVehicleDetails
                        .status === InspectionSecurityStatusEnum.noRecord) ||
                      (inspectionDetailData.inspectionChecks.vin &&
                        inspectionDetailData.inspectionChecks.licencePlate &&
                        ([
                          InspectionSecurityStatusEnum.noRecord,
                          InspectionSecurityStatusEnum.notPerformed,
                          InspectionSecurityStatusEnum.error
                        ].includes(
                          inspectionDetailData.inspectionChecks.vin?.nevdis
                            ?.result
                        ) ||
                          inspectionDetailData.inspectionChecks.vin?.status ===
                            InspectionSecurityStatusEnum.notPerformed) &&
                        ([
                          InspectionSecurityStatusEnum.noRecord,
                          InspectionSecurityStatusEnum.error,
                          InspectionSecurityStatusEnum.notPerformed
                        ].includes(
                          inspectionDetailData.inspectionChecks.licencePlate
                            ?.nevdis?.result
                        ) ||
                          inspectionDetailData.inspectionChecks.licencePlate
                            ?.status ===
                            InspectionSecurityStatusEnum.notPerformed) &&
                        ![
                          inspectionDetailData.inspectionChecks.licencePlate
                            .status,
                          inspectionDetailData.inspectionChecks.vin.status,
                          inspectionDetailData.inspectionChecks
                            .nevdisVehicleDetails.status
                        ].every(
                          (i) => i === InspectionSecurityStatusEnum.notPerformed
                        ) && (
                          <p className="text-grey-900 text-center max-w-[527px]">
                            The vehicle identifiers provided cannot be found on
                            NEVDIS.
                            <br />
                            Please ensure the VIN and Number Plate details
                            provided are correct.
                          </p>
                        ))}
                  </div>
                )}
              </>
            )}
            {activeStep === '3' &&
              registrationDetails &&
              Object.keys(registrationDetails).length > 2 && (
                <div className="grid grid-cols-1 md:grid-cols-3 sm:grid-cols-1 xl:grid-cols-3 auto-cols-max px-4 gap-3 xl:gap-y-6 md:gap-y-6 w-full pb-6">
                  <RegoPaper registration={registrationDetails} />
                </div>
              )}
            {activeStep === '3' &&
              (!registrationDetails ||
                Object.keys(registrationDetails).length <= 2) && (
                <div className="flex justify-center items-center h-full w-full flex-col gap-y-6 min-h-[240px]">
                  <NoRegistration />
                  <p className="text-grey-900 font-semibold text-center">
                    {'Registration Details Pending'}
                  </p>
                </div>
              )}
            {activeStep === '4' &&
              bankDetails &&
              Object.keys(bankDetails).length > 2 && (
                <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-1 xl:grid-cols-4 auto-cols-max px-4 gap-3 xl:gap-y-6 md:gap-y-6 w-full pb-6">
                  <BankStatement bank={bankDetails} />
                </div>
              )}
            {activeStep === '4' &&
              (!bankDetails || Object.keys(bankDetails).length <= 2) && (
                <div className="flex justify-center items-center h-full w-full flex-col gap-y-6 min-h-[240px]">
                  <NoBank />
                  <p className="text-grey-900 font-semibold text-center">
                    {'Bank Statement Details Pending'}
                  </p>
                </div>
              )}
            {activeStep === '5' &&
              inspectionDetailData.inspectionChecks.ROADWORTHY_CERT && (
                <div className="grid grid-cols-1 md:grid-cols-4 sm:grid-cols-1 xl:grid-cols-4 auto-cols-max px-4 gap-3 xl:gap-y-6 md:gap-y-6 w-full pb-6">
                  <Cert
                    cert={inspectionDetailData.inspectionChecks.ROADWORTHY_CERT}
                  />
                </div>
              )}
            {activeStep === '5' &&
              !inspectionDetailData.inspectionChecks.ROADWORTHY_CERT && (
                <div className="flex justify-center items-center h-full w-full flex-col gap-y-6 min-h-[240px] hidden">
                  <NoCert />
                  <p className="text-grey-900 font-semibold text-center">
                    {'Roadworthy Certificate Pending'}
                  </p>
                </div>
              )}
          </div>
        </div>
        {showSecurityCheck && (
          <div className="flex flex-col gap-3 col-span-6 xl:col-span-2 xl:order-2 lg:order-3 mt-8 lg:mt-8 xl:mt-0">
            <SecurityCheck
              activeStep={activeStep}
              docType={docTypes[parseInt(activeStep) - 1]}
              data={items}
            />
          </div>
        )}
        <div className=" col-span-6 xl:col-span-6 xl:order-3 lg:order-2 mt-8 lg:mt-8 gap-3 flex flex-col">
          <Documents
            activeStep={activeStep}
            docType={docTypes[parseInt(activeStep) - 1]}
            data={items}
          />
        </div>
      </div>
    </>
  );
};

export default InspectionMainInfo;
